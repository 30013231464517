import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setPersonalizedQuestionsProgressAction } from "../../../../redux/actions/productAction";
import { SECTION_BUTTONS } from "./data/section-buttons";

import classes from "./SectionButtons.module.css";

export default function SectionButtons({
  activeSection,
  setActiveSection,
  quantityTabState
}) {
  const dispatch = useDispatch();

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  console.info("activeSection2", activeSection);

  useEffect(() => {
    if (personalizedQuestionsProgressState.progress !== undefined) {
      const container = document.getElementById("chequeCustomizationContainer");
      if (container) {
        container.scrollTop = 0;
      }
      setActiveSection(
        SECTION_BUTTONS.find(
          btn => btn.progress === personalizedQuestionsProgressState.progress
        )
      );
    }
  }, [personalizedQuestionsProgressState.progress]);

  return (
    <div className={classes.sectionBtns}>
      {SECTION_BUTTONS.map(btn => (
        <div
          onClick={() => {
            if (btn.progress === 0) {
              dispatch(
                setPersonalizedQuestionsProgressAction({
                  displayQuestions: false
                })
              );
            }
            if (btn.progress < activeSection.progress)
              dispatch(
                setPersonalizedQuestionsProgressAction({
                  progress: btn.progress
                })
              );
          }}
          style={{
            width: `${100 / SECTION_BUTTONS.length}%`,
            backgroundColor:
              btn.progress <= activeSection.progress ? "#f5f5f5" : "#cccccc",
            color: btn.progress < activeSection.progress ? "#1175bd" : null,
            cursor: btn.progress < activeSection.progress ? "pointer" : null,
            textDecoration:
              btn.progress < activeSection.progress ? "underline" : null
          }}
          className={`${classes.sectionBtn}${
            activeSection.name === btn.name ? " " + classes.active : ""
          }`}
          name={btn.name}
          data-progress={btn.progress.toString()}
          //onClick={() => handleSectionClicked(btn)}
        >
          <h6>{btn.title}</h6>
          {/*  {btn.progress < activeSection.progress && (
              <p
                onClick={() => {
                  dispatch(
                    setPersonalizedQuestionsProgressAction({
                      progress: btn.progress
                    })
                  );
                }}
              >
                Edit
              </p>
            )} */}
        </div>
      ))}
    </div>
  );
}
