import React from "react";

import { I18nContext } from "../../../../i18n/index";

const ItemCode = ({ code }) => {
  const { translate } = React.useContext(I18nContext);

  return (
    <div className="item-code-row col-xs-12" style={{ paddingLeft: "0px" }}>
      <p
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: "1.25em"
        }}
      >
        <span>Code de produit: </span>&nbsp;
        <span style={{ fontWeight: "400" }}>{code}</span>
      </p>
    </div>
  );
};

export default ItemCode;
