import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  setPersonalizedQuestionsProgressAction,
  setUserInputsPersonalizedQuestions,
  setChequeValidations,
  setPersonalizedQuestionsAction
} from "../../../../redux/actions/productAction";
import RadioButtonGroup from "./components/RadioButtonGroup";
import TextInputField from "./components/TextInputField";
import ReviewSection from "./components/ReviewSection";
import ExtendedLogoSection from "./components/ExtendedLogoSection";
import Dropdown from "./components/Dropdown";
import BundleItemsMessage from "./components/BundleItemsMessage";
import Checkbox from "./components/Checkbox";
import { SECTION_BUTTONS } from "./data/section-buttons";

import classes from "./ChequeCustomization.module.css";
import SectionButtons from "./SectionButtons";

export default function ChequeCustomization({
  activeSection,
  setActiveSection,
  buttonRef,
  lastInputRef,
  firstInputRef,
  imageCaroRef,
  setTempRef,
  tempRef,
  sectionRef
}) {
  const dispatch = useDispatch();

  const [
    questionIdsWithFailedValidations,
    setQuestionIdsWithFailedValidations
  ] = useState([]);

  const [defaultValues, setDefaultValues] = useState(null);

  const customerPropsState = useSelector(
    state => state.loginReducer.customerProps,
    shallowEqual
  );

  const personalizedState = useSelector(
    state => state.productReducer.itemDetail.personalized,
    shallowEqual
  );

  const personalizedQuestionsValidationErrorsState = useSelector(
    state => state.productReducer.personalizedQuestionsValidationErrors,
    shallowEqual
  );

  const productInitialState = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const productInitialBundleState = useSelector(
    state => state.productReducer.productInitial.bundle,
    shallowEqual
  );

  const personalizedQuestionsValidations = useSelector(
    state => state.productReducer.personalizedQuestionsValidations,
    shallowEqual
  );
  const currentItemIsBundleItemState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress
        .currentItemIsBundleItem,
    shallowEqual
  );
  const bundleItemsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.bundleItems,
    shallowEqual
  );

  useEffect(() => {
    console.info(
      "cheque5",
      bundleItemsState,
      personalizedState,
      currentItemIsBundleItemState
    );
    if (
      bundleItemsState &&
      bundleItemsState.length > 0 &&
      personalizedState === false &&
      !currentItemIsBundleItemState
    ) {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          displayBundleItemsMessage: true
        })
      );
    }
  }, [personalizedState, bundleItemsState]);

  const setPersonalizedQuestionsState = questions => {
    dispatch(setPersonalizedQuestionsAction(questions));
  };

  useEffect(() => {
    console.info("cheque initial", productInitialBundleState);
    if (productInitialBundleState) {
      const itemId = productInitialState.id;
      const initialBundleItemsIds = productInitialBundleState.reduce((a, c) => {
        a.push(c.id);

        return a;
      }, []);

      if (bundleItemsState) {
        const bundleItemsIds = bundleItemsState.reduce((a, c) => {
          a.push(c.id);

          return a;
        }, []);
        console.info("cheque initial2", itemId);
        if (bundleItemsIds.includes(itemId)) {
          const index = bundleItemsIds.indexOf(itemId);

          dispatch(
            setPersonalizedQuestionsProgressAction({
              progress: 1,
              currentItemIsBundleItem: true,
              bundleItemsProgress: index
            })
          );
          dispatch(setPersonalizedQuestionsAction({}));
        }
      } else {
        dispatch(
          setPersonalizedQuestionsProgressAction({
            currentItemIsBundleItem: initialBundleItemsIds.includes(itemId),
            bundleItemsProgress: 0,
            bundleItems: [...productInitialBundleState]
          })
        );
      }
    }
  }, [productInitialBundleState]);

  useEffect(() => {
    if (Object.keys(personalizedQuestionsValidationErrorsState).length > 0) {
      let ids = Object.keys(personalizedQuestionsValidationErrorsState);
      setQuestionIdsWithFailedValidations(ids);

      let firstQuestionDetailId = Object.keys(
        personalizedQuestionsValidationErrorsState[ids[0]]
      )[0];

      if (firstQuestionDetailId === "qID") {
        firstQuestionDetailId = ids[0];
      }

      console.info("ids2", ids, firstQuestionDetailId);

      const line = document.getElementById(firstQuestionDetailId);
      const container = document.getElementById("chequeCustomizationContainer");

      if (line && container) {
        const topPos = line.offsetTop;
        container.scrollTop = topPos;
        line.focus();
      }
    }
  }, [personalizedQuestionsValidationErrorsState]);

  useEffect(() => {
    if (customerPropsState && customerPropsState.length > 0) {
      setDefaultValues(
        customerPropsState.reduce((a, c) => {
          if (c.key.toLowerCase().includes("comet_")) {
            a[c.key] = c.value;
          }

          return a;
        }, {})
      );
    } else setDefaultValues({});
  }, [customerPropsState]);

  const state = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.inputs,
    shallowEqual
  );
  const setState = payload => {
    dispatch(setUserInputsPersonalizedQuestions(payload));
  };

  const setValidationsState = payload => {
    dispatch(setChequeValidations(payload));
  };

  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const setProgressState = payload => {
    dispatch(setPersonalizedQuestionsProgressAction(payload));
  };

  useEffect(() => {
    console.info("cheque initial3", personalizedQuestionsState);
    if (
      personalizedQuestionsState &&
      Object.keys(personalizedQuestionsState).length > 0
    ) {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          displayQuestions: true,
          progress: 1
        })
      );
    } else {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          displayQuestions: false
        })
      );
    }
  }, [personalizedQuestionsState]);

  const renderDependedInputSection = question => {
    if (personalizedQuestionsState.dependedFields.length > 0) {
      if (question.dependingFieldCode) {
        let foundQuestion = personalizedQuestionsState.dependedFields.find(
          q => q.quesCode === question.dependingFieldCode
        );

        console.info(
          "foundQuestion",
          `'${question.dependingFieldCode}'`,
          personalizedQuestionsState.dependedFields,
          foundQuestion
        );

        if (foundQuestion) {
          if (foundQuestion.dependedOn === "logoMode") {
            if (
              foundQuestion.logoModeRequiredState.includes(
                personalizedQuestionsProgressState.logo.logoMode
              )
            ) {
              const readOnly =
                personalizedQuestionsProgressState.logo.logoMode ===
                "pickClipart";
              return renderTheInput(foundQuestion, readOnly);
            }
          }
        }

        return null;
      }
    }
    return null;
  };

  const renderExtendedInputSection = question => {
    if (question.quesDesc !== "Logo Options") return;

    let logoMode = personalizedQuestionsProgressState.logo.logoMode;
    if (!logoMode) return null;
    else {
      return (
        <ExtendedLogoSection
          questions={personalizedQuestionsState}
          dependedFields={personalizedQuestionsState.dependedFields}
          setInputsState={setState}
          dispatch={dispatch}
          logoMode={logoMode}
          progressState={personalizedQuestionsProgressState}
        />
      );
    }
  };

  const renderTheInput = (question, readOnly, lastIndex) => {
    if (question.quesInput === "radio") {
      let type = null;
      let buttons = question.quesDetails;
      if (question.quesType.toLowerCase().includes("color")) {
        type = "color";
        buttons = buttons.map(btn => {
          btn.label = btn.previewVal;
          btn.altLabel = btn.detailImg;
          btn.id = btn.id = btn.detailID;
          btn.linesLength = lastIndex;
          return btn;
        });
      } else if (question.quesType === "typeStyle") {
        type = "image";
        buttons = buttons.map(btn => {
          btn.label = btn.previewVal;
          btn.altLabel = btn.detailImg;
          btn.id = btn.id = btn.detailID;
          btn.linesLength = lastIndex;
          return btn;
        });

        return (
          <Dropdown
            fontSelect={true}
            state={state}
            setState={setState}
            type={type}
            name={question.quesID}
            label={question.quesDesc}
            options={buttons}
            firstOptionIsCheckedByDefault={true}
            dispatch={dispatch}
            progressState={personalizedQuestionsProgressState}
            setProgressState={setProgressState}
            buttonRef={buttonRef}
            lastInputRef={lastInputRef}
            lastIndex={lastIndex}
          />
        );
      } else {
        buttons = buttons.map(btn => {
          btn.label = btn.detailDesc;
          btn.id = btn.id = btn.detailID;
          btn.linesLength = lastIndex;
          return btn;
        });
      }

      const failedValidations =
        personalizedQuestionsValidationErrorsState[question.quesID];

      if (
        ["splInstrDetails-software", "splInstrDetailsSoftware"].includes(
          question.quesCode
        ) ||
        question.quesCode.match(/software-\w*/gi) ||
        question.quesCode.match(/software.* question/gi)
      ) {
        return (
          <Dropdown
            state={state}
            setState={setState}
            type={type}
            name={question.quesID}
            label={question.quesDesc}
            options={buttons}
            firstOptionIsCheckedByDefault={false}
            dispatch={dispatch}
            progressState={personalizedQuestionsProgressState}
            setProgressState={setProgressState}
            required={true}
            selectLabel="Choose Software"
            setValidations={setValidationsState}
            failedValidations={failedValidations}
          />
        );
      } else {
        const needsCheckboxActivation = ["textColor2"].includes(
          question.quesCode
        );

        const InkColorQuestion = ["Imprint Colour", "2nd Ink Colour"].includes(
          question.quesDesc
        );

        return (
          <RadioButtonGroup
            state={state}
            setState={setState}
            type={type}
            name={question.quesID}
            label={question.quesDesc}
            buttons={[
              ...buttons.map(btn => {
                return {
                  ...btn,
                  linesLength: lastIndex
                };
              })
            ]}
            firstOptionIsCheckedByDefault={true}
            dispatch={dispatch}
            progressState={personalizedQuestionsProgressState}
            dependedFields={personalizedQuestionsState.dependedFields}
            InkColorQuestion={InkColorQuestion}
            needsCheckboxActivation={needsCheckboxActivation}
            setValidations={setValidationsState}
            failedValidations={failedValidations}
            buttonRef={buttonRef}
            lastIndex={lastIndex}
            setTempRef={setTempRef}
            tempRef={tempRef}
          />
        );
      }
    } else if (question.quesInput === "text") {
      let validationFailed = questionIdsWithFailedValidations.includes(
        question.quesID
      );
      let lines = question.quesDetails.map((line, index) => {
        line.id = line.detailID;
        line.label = line.detailDesc;
        line.index = index;
        line.linesLength = lastIndex;
        line.failedValidations =
          personalizedQuestionsValidationErrorsState[question.quesID] &&
          personalizedQuestionsValidationErrorsState[question.quesID][
            line.detailID
          ];
        line.defaultValue =
          line && line.answer !== undefined
            ? line.answer
            : defaultValues && defaultValues[line.defWords];
        return line;
      });

      return (
        <TextInputField
          readOnly={readOnly}
          validationFailed={validationFailed}
          validations={personalizedQuestionsValidations}
          setValidations={setValidationsState}
          state={state}
          setState={setState}
          field={question.quesID}
          lines={lines}
          label={question.quesDesc}
          softwareEmailProof={question.quesCode === "softwareEmailProof"}
          setQuestions={setPersonalizedQuestionsState}
          questions={personalizedQuestionsState}
          buttonRef={buttonRef}
          lastInputRef={lastInputRef}
          firstInputRef={firstInputRef}
          progressState={personalizedQuestionsProgressState}
        />
      );
    } else if (question.quesInput === "checkbox") {
      let type = null;
      let buttons = question.quesDetails;
      if (question.quesType.toLowerCase().includes("color")) {
        type = "color";
        buttons = buttons.map(btn => {
          btn.label = btn.previewVal;
          btn.altLabel = btn.detailImg;
          btn.id = btn.id = btn.detailID;
          btn.linesLength = lastIndex;
          return btn;
        });
      } else {
        buttons = buttons.map(btn => {
          btn.label = btn.detailDesc;
          btn.id = btn.id = btn.detailID;
          btn.linesLength = lastIndex;
          return btn;
        });
      }

      const firstOptionIsCheckedByDefault =
        "TextOptions-SL" !== question.quesCode;
      return (
        <Checkbox
          state={state}
          setState={setState}
          type={type}
          name={question.quesID}
          label={question.quesDesc}
          buttons={buttons}
          firstOptionIsCheckedByDefault={firstOptionIsCheckedByDefault}
          dispatch={dispatch}
          progressState={personalizedQuestionsProgressState}
        />
      );
    } else return null;
  };

  const renderTheSection = name => {
    console.info("defaultValues", defaultValues);

    const renderTheQuestion = (question, lastIndex) => {
      return (
        <div className={classes.questionWrapper}>
          {renderTheInput(question, undefined, lastIndex)}
          {renderDependedInputSection(question, lastIndex)}
          {renderExtendedInputSection(question, lastIndex)}
        </div>
      );
    };

    if (name === "checkInformationSection") {
      if (
        personalizedQuestionsState &&
        personalizedQuestionsState.textFields &&
        personalizedQuestionsState.textFields.length > 0
      ) {
        return (
          <div className={classes.questionsWrapper}>
            {personalizedQuestionsState.textFields.map((question, index) =>
              renderTheQuestion(question, {
                len: personalizedQuestionsState.textFields.length,
                ind: index
              })
            )}
          </div>
        );
      }
    } else if (name === "checkDesignSection") {
      if (
        personalizedQuestionsState &&
        personalizedQuestionsState.restOfTheFields &&
        personalizedQuestionsState.restOfTheFields.length > 0
      ) {
        return (
          <div className={classes.questionsWrapper}>
            {personalizedQuestionsState.restOfTheFields.map(
              (question, index) =>
                question.renderDefault !== false &&
                renderTheQuestion(question, {
                  len: personalizedQuestionsState.restOfTheFields.length,
                  ind: index
                })
            )}
          </div>
        );
      }
    } else if (name === "checkReviewSection") {
      let keys = Object.keys(personalizedQuestionsState);
      let allTheQuestions = [];
      keys.forEach(key => {
        allTheQuestions = [
          ...allTheQuestions,
          ...personalizedQuestionsState[key]
        ];
      });
      return (
        <ReviewSection
          questions={allTheQuestions}
          inputs={state}
          progressState={personalizedQuestionsProgressState}
          firstInputRef={firstInputRef}
          lastInputRef={lastInputRef}
          buttonRef={buttonRef}
          setTempRef={setTempRef}
          tempRef={tempRef}
        />
      );
    } else return null;
  };

  const renderTheSections = () => {
    return (
      <div className={classes.sectionsWrapper}>
        {SECTION_BUTTONS.map(section => (
          <div
            className={`${classes.sectionWrapper}${
              section.progress === activeSection.progress
                ? " " + classes.active
                : ""
            }`}
            id={section.name}
          >
            {renderTheSection(section.name)}
          </div>
        ))}
      </div>
    );
  };

  if (
    personalizedQuestionsProgressState.displayBundleItemsMessage === true &&
    personalizedQuestionsProgressState.displayQuestions === false
  ) {
    return (
      <BundleItemsMessage
        dispatch={dispatch}
        bundleItems={bundleItemsState}
        displayBundleItemsMessage={
          personalizedQuestionsProgressState.displayBundleItemsMessage
        }
      />
    );
  }
  if (personalizedQuestionsProgressState.displayQuestions)
    return (
      <React.Fragment>
        <BundleItemsMessage
          dispatch={dispatch}
          bundleItems={bundleItemsState}
          displayBundleItemsMessage={
            personalizedQuestionsProgressState.displayBundleItemsMessage
          }
        />
        <div className={classes.outerContainer}>
          <SectionButtons {...{ activeSection, setActiveSection }} />

          <div id="chequeCustomizationContainer" className={classes.container}>
            <div className={classes.wrapper}>
              <div className={classes.sectionsAndHeaderWrapper}>
                {/*  {renderSectionHeader()} */}
                {renderTheSections()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  else return null;
}
