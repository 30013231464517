import React from "react";
import { graphql } from "gatsby";
import Layout from "../other/layout";
import SEO from "../other/seo";
import ProductPage from "../components/ACG-ProductPage/OFBProductPage";
import { PROJECT_LINK, SOLE_PROJECT_LINK, VID } from "../project-config";

export const query = graphql`
  query MyQueryPreview($url: String) {
    allAvettiProductPreview(filter: { url: { eq: $url } }) {
      nodes {
        url
        _0 {
          id
          breadcrumbs {
            name
            url
          }
        }
        productData {
          _xResult {
            code
            image
            itemId
            title
            shortdesc
            longdesc
            longdesc2
            longdesc3
            longdesc4
            hiddenProperties {
              propname
              propvalue
            }
            attributes {
              attributeid
              itemid
              position
            }
            mainitemid
            prices {
              packagePrices {
                offerPriceId
                packagePriceId
                price
                quantity
              }
              price_1
              listprice
            }
            vendorId
          }
        }
      }
    }
  }
`;
const product = ({ data }) => {
  const { productData } = data.allAvettiProductPreview.nodes[0];
  const code = productData._xResult[0].code;
  const imageUrl = `${PROJECT_LINK}/store/${VID}/items/largeimages/${code}.jpg`;

  let storeInfo = {
    storeSellerData: [],
    pickupLocData: [],
    supplierShippingInfo: []
  };
  /*   console.info(
    "DATA PRODUCT PREVIEW***** VID:",
    data.allAvettiProductPreview.nodes[0].supplierData._xResult[0]
      .distributorOrder[0] &&
      data.allAvettiProductPreview.nodes[0].supplierData._xResult[0]
        .distributorOrder[0].supplier_vid,
    " storeInfo:",
    storeInfo
  ); */
  return (
    <Layout>
      <SEO
        description={productData._xResult[0].shortdesc}
        lang={"fr"}
        title={productData._xResult[0].title}
        code={code}
        pathname={data.allAvettiProductPreview.nodes[0].url}
        meta={[
          {
            name: `og:image`,
            content: imageUrl
          },
          {
            name: `og:image:secure_url`,
            content: imageUrl
          },
          {
            name: `twitter:image`,
            content: imageUrl
          },
          {
            name: `og:url`,
            content: `${SOLE_PROJECT_LINK}/${
              data.allAvettiProductPreview.nodes[0].url.includes("product")
                ? data.allAvettiProductPreview.nodes[0].url.replace(
                    "product/",
                    ""
                  )
                : data.allAvettiProductPreview.nodes[0].url
            }`
          }
        ]}
      />
      <ProductPage
        data={data.allAvettiProductPreview.nodes[0]}
        storeInfo={storeInfo}
      />
    </Layout>
  );
};

export default product;
