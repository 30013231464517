import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LazyLoad from "react-lazyload";
import "./Styles/ImageCarousel.css";
import { setProductImagecarouselInitialAction } from "../../../../redux/actions/productAction";
import { usePrevious } from "../../../../functions/Utilities";

import LazyloadImage from "../../../OFB-Category/components/ItemCard/components/LazyLoadImage";

import ShareButtons from "../../Components/ShareButtons/ShareButtons";
import ShareIcon from "@material-ui/icons/Share";

import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {
  PROJECT_LINK,
  VID,
  LINK_DISTRIBUTION
} from "../../../../project-config";

const areEqual = (prevProps, nextProps) => {
  console.info("borop rerender", nextProps);
  return nextProps.productImageSwitch == false;
};

const ImageCarousel = React.memo(function ImageCarousel(props) {
  const dispatch = useDispatch();
  const [mainImage, setMainImage] = useState("");

  const [mainImageUrl, setMainImageUrl] = useState("");

  const [wholeImageUrl, setWholeImageUrl] = useState("");
  const [galleryImagesCount, setGalleryImagesCount] = useState([]);
  const [sysNumImages, setSysNumImages] = useState(0);

  const [image404, setImage404] = useState(null);

  const [logo, setLogo] = useState(null);
  const productImageSwitchState = props.productImageSwitch;

  const productPageImageCarouselInitialState = useSelector(
    state => state.productReducer.productPageImageCarouselInitial,
    shallowEqual
  );
  console.info(
    "farop state",
    props,
    mainImageUrl,
    productImageSwitchState,
    productPageImageCarouselInitialState,
    wholeImageUrl
  );

  const productInitialState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const productCode = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  const loadingState = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const itemTitleState = useSelector(
    state => state.productReducer.itemDetail.title,
    shallowEqual
  );

  const previousItemIdState = usePrevious(
    Object.assign({}, { itemIdState, itemTitleState })
  );

  const propertiesState = useSelector(
    state => state.productReducer.itemDetail.properties,
    shallowEqual
  );

  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const personalizedQuestionsProgressDisplayQuestionsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.displayQuestions,
    shallowEqual
  );

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const mapColors = colors => {
    return colors.map(color => {
      color = color.toLowerCase();
      if (color === "navy blue") color = "navy";
      else if (color === "burgundy") color = "#800020";
      else if (color === "hunter green") color = "#355e3b";
      else if (color === "reflex blue") color = "#171796";
      return color;
    });
  };
  console.info("borop rerender2", productImageSwitchState);

  useEffect(() => {
    console.info("farop prev", previousItemIdState, itemIdState);
    if (
      previousItemIdState &&
      previousItemIdState.itemIdState != undefined &&
      previousItemIdState.itemIdState != itemIdState
    ) {
      if (previousItemIdState.itemTitleState == itemTitleState)
        dispatch(setProductImagecarouselInitialAction(false));
    }
  }, [previousItemIdState]);

  useEffect(() => {
    if (productImageSwitchState || productPageImageCarouselInitialState) {
      console.info("borop rerender3", productImageSwitchState);

      setMainImage(productCode);
    }
  }, [productCode]);

  useEffect(() => {
    if (
      (productImageSwitchState || productPageImageCarouselInitialState) &&
      personalizedQuestionsProgressDisplayQuestionsState === false
    ) {
      setMainImageUrl(`${LINK_DISTRIBUTION}/store/${VID}/items/largeimages/`);
    } else if (personalizedQuestionsProgressDisplayQuestionsState) {
      setMainImageUrl(
        `${LINK_DISTRIBUTION}/store/${VID}/items/largeimages/` //backgrounds
      );
    }
  }, [itemIdState, personalizedQuestionsProgressDisplayQuestionsState]);

  useEffect(() => {
    if (
      mainImage &&
      mainImageUrl
      /*   (mainImage && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState) */
    ) {
      setWholeImageUrl(`${mainImageUrl}${mainImage}.jpg`);
    }
  }, [mainImage, mainImageUrl]);

  useEffect(() => {
    console.info("borop image", productImageSwitchState, propertiesState);
    if (
      (propertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState)
    ) {
      let tempNumber = propertiesState.filter(prop => {
        if (prop.propname === "Sys_Gallery_Images") {
          return true;
        } else {
          return false;
        }
      });

      tempNumber = tempNumber && tempNumber[0] && tempNumber[0].propvalue;
      let tempArray = [];

      for (let i = 1; i < Number(tempNumber); i++) {
        tempArray.push(i);
      }
      console.info("tempArray", tempArray);

      setGalleryImagesCount(tempArray);
    }
  }, [propertiesState]);

  useEffect(() => {
    console.info("borop image2", productImageSwitchState);

    if (
      (hiddenPropertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && hiddenPropertiesState)
    ) {
      let prop = hiddenPropertiesState.find(prop => {
        return prop.propname == "Sys_Num_Images";
      });
      if (prop === undefined) {
        setSysNumImages(0);
      } else if (parseInt(prop.propvalue) >= 1) {
        // setSysNumImages(parseInt(prop.propvalue));
      }
    }
  }, [hiddenPropertiesState]);

  const renderTheInputsOnTheImage = () => {
    if (
      personalizedQuestionsProgressState &&
      personalizedQuestionsProgressState.inputs &&
      Object.keys(personalizedQuestionsProgressState.inputs).length > 0 &&
      personalizedQuestionsState &&
      Object.keys(personalizedQuestionsState).length > 0
    ) {
      let textFieldsIdsAndCoords =
        personalizedQuestionsState &&
        personalizedQuestionsState.textFields.reduce((a, question) => {
          let fontStyleMICRDigits = question.quesType === "startNumber";
          question.quesDetails.forEach(detail => {
            a.push({
              applyFontStyle: detail.defWords === "Comet_ML",
              fontStyleMICRDigits,
              id: detail.detailID,
              x: detail.previewX,
              y: detail.previewY,
              h: detail.previewH,
              w: detail.previewW
            });
          });

          return a;
        }, []);

      let otherTextFieldsInsideRestOfTheFields = personalizedQuestionsState.restOfTheFields.filter(
        question => question.quesInput === "text"
      );

      let otherTextFields = otherTextFieldsInsideRestOfTheFields.reduce(
        (a, question) => {
          question.quesDetails.forEach(detail => {
            a.push({
              applyFontStyle: detail.defWords === "Comet_ML",
              id: detail.detailID,
              x: detail.previewX,
              y: detail.previewY,
              h: detail.previewH,
              w: detail.previewW
            });
          });

          return a;
        },
        []
      );

      if (otherTextFields) textFieldsIdsAndCoords.push(...otherTextFields);

      let logoQuestion = personalizedQuestionsState.restOfTheFields.find(
        question => question.quesCode === "Logo"
      );

      let logoToRender = null;

      let logoMode = personalizedQuestionsProgressState.logo.logoMode;
      if (logoMode) {
        let quesDetailID =
          personalizedQuestionsProgressState.inputs[logoQuestion.quesID];

        let quesDetail = logoQuestion.quesDetails.find(
          detail => detail.detailID === quesDetailID
        );

        let { previewX: x, previewY: y, previewH: h, previewW: w } = quesDetail;

        if (logoMode === "pickClipart")
          logoToRender =
            personalizedQuestionsProgressState.logo.selectedClipart &&
            personalizedQuestionsProgressState.logo.selectedClipart.url;
        else if (logoMode === "uploadLogo")
          logoToRender = personalizedQuestionsProgressState.logo.uploadedLogo;

        logoToRender = {
          alt: quesDetail.detailDesc,
          id: logoQuestion.quesID,
          logo: logoToRender,
          x,
          y,
          h,
          w
        };
      }

      if (logoToRender) textFieldsIdsAndCoords.push(logoToRender);

      console.info("textFieldsIdsAndCoords", textFieldsIdsAndCoords);

      return textFieldsIdsAndCoords.map(field => {
        if (field && field.logo) {
          // setLogo(field.logo);
          return (
            <div
              style={{
                // display: field.w === "0" || field.h === "0" ? "none" : "",
                top: `${field.y}px`,
                left: `${field.x}px`,
                width: "50px", //`${field.w}px`,
                height: "50px" //`${field.h}px`
              }}
              className="pq-user-input-over-image pq-logo-wrapper"
              data-id={field.id}
            >
              {/*   <canvas ref={canvasRef}></canvas> */}
              <img id={"pq-logo"} src={field.logo} alt={field.alt} />
            </div>
          );
        } else if (field) {
          console.info("borop4", field);
          let {
            selectedFont,
            "Imprint Colour": imprintColor,
            "2nd Ink Color": secondInkColor
          } = personalizedQuestionsProgressState.font;
          let colors = mapColors([imprintColor, secondInkColor]);
          return (
            <div
              style={{
                display: field.w === "0" || field.h === "0" ? "none" : "",
                top: `${field.y}px`,
                left: `${field.x}px`,
                width: `${field.w}px`,
                height: `${field.h}px`,
                color: `${
                  field.applyFontStyle ? colors[0] : colors[1] || colors[0]
                }`
              }}
              className={`pq-user-input-over-image${
                field.fontStyleMICRDigits
                  ? " MICRDigits"
                  : selectedFont && field.applyFontStyle
                  ? " " + selectedFont
                  : ""
              }`}
              data-id={field.id}
              dangerouslySetInnerHTML={{
                __html:
                  personalizedQuestionsProgressState.inputs[field.id] &&
                  personalizedQuestionsProgressState.inputs[field.id] !==
                    "Not provided:  Leave blank"
                    ? personalizedQuestionsProgressState.inputs[field.id]
                    : ""
              }}
            ></div>
          );
        } else return null;
      });
    }
    return null;
  };

  const renderMagnifier = () => {
    if (wholeImageUrl) {
      const imageUrl = wholeImageUrl.replace("/images", "/largeimage");

      let urlToUse = image404
        ? `${LINK_DISTRIBUTION}/store/${VID}/items/largeimages/noimage.jpg`
        : imageUrl;
      return (
        <div className={`${isMobileState ? "mobile-" : ""}magnifier-container`}>
          <TransformWrapper defaultScale={1} positionX={0} positionY={0}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
              return (
                <React.Fragment>
                  <div className="zoom-tools">
                    <button onClick={zoomIn}>
                      <i className="material-icons">zoom_in</i>
                    </button>
                    <button onClick={zoomOut}>
                      <i className="material-icons">zoom_out</i>
                    </button>
                    <button
                      onClick={resetTransform}
                      ref={props.imageCaroRef}
                      onKeyDown={e => {
                        console.log("SHIFT KEY RESIM", props.sectionRef);
                        if (
                          !e.shiftKey &&
                          e.keyCode === 9 &&
                          props.sectionRef &&
                          props.sectionRef.current
                        ) {
                          e.preventDefault();
                          props.sectionRef.current.focus();
                        }
                      }}
                    >
                      <i className="material-icons">zoom_out_map</i>
                    </button>
                  </div>
                  <TransformComponent>
                    {renderTheInputsOnTheImage()}
                    <img
                      onError={() => {
                        if (image404 === null) setImage404(true);
                      }}
                      /*   onLoad={resetTransform} */
                      src={urlToUse}
                      alt={`${itemTitleState}`}
                    ></img>
                  </TransformComponent>
                </React.Fragment>
              );
            }}
          </TransformWrapper>
        </div>
      );
    }
  };

  console.info("imageCarousel", galleryImagesCount.length, " ", sysNumImages);
  console.info(
    "farop img check",
    loadingState,
    wholeImageUrl,
    itemIdState,
    personalizedQuestionsProgressDisplayQuestionsState
  );
  return (
    <div id="imageGrid">
      <div className="preview" id="item-img">
        {renderMagnifier()}
      </div>
    </div>
  );
}, areEqual);

export default ImageCarousel;
