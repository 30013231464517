import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";

const OtherInfoTab = ({ longDesc, properties }) => {
  const { translate } = React.useContext(I18nContext);

  return (
    <div
      className="product-details-specs-container"
      style={{ backgroundColor: "white" }}
    >
      <div className="product-details-specs-wrapper">
        <div className="product-details-wrapper">
          <h2 className="otherInfoH2">À propos de cet article</h2>
          <div id="description" className="otherDesktop">
            {Object.keys(longDesc).map((desc, index) => {
              if (longDesc[desc] !== "") {
                return (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: htmldecoder(longDesc[desc])
                    }}
                  />
                );
              }
            })}
          </div>
        </div>

        <div className="product-specs-wrapper">
          <h2 className="otherInfoH2">{translate("js.item.specifications")}</h2>
          <div id="Specifi">
            {properties && properties.length > 0 ? (
              <div className="specs-list">
                <table className="table table-striped">
                  <tbody>
                    {properties
                      ? properties
                          .slice(0, properties.length / 2)

                          .map(property => {
                            return (
                              <tr key={property.propname}>
                                <th>
                                  <span>
                                    {property.propname.split("_").join(" ")}
                                  </span>
                                </th>
                                <td>{property.propvalue}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table>

                <table className="table table-striped">
                  <tbody>
                    {properties
                      ? properties
                          .slice(properties.length / 2, properties.length)
                          .map(property => {
                            return (
                              <tr key={property.propname}>
                                <th>
                                  <span>
                                    {property.propname.split("_").join(" ")}
                                  </span>
                                </th>
                                <td>{property.propvalue}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table>
              </div>
            ) : (
              <h3>Il n'y a aucune caractéristiques à afficher</h3>
            )}
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInfoTab;
