import React, { useCallback, useEffect } from "react";

import { useDropzone } from "react-dropzone";
import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";
import handleUploadLogo from "../utils/handleUploadLogo";

import classes from "./LogoUpload.module.css";

export default function LogoUpload({
  setInputsState,
  logoInputField,
  progressState,
  dispatch
}) {
  const uploadedLogo = progressState.logo.uploadedLogo;
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    let newFile = acceptedFiles.pop();
    const reader = new FileReader();
    const callBack = json => {
      console.info("status logo upload", json);
      if (json && json.fileName && json.longDir) {
        setInputsState({
          [logoInputField.detailID]: [json.fileName, json.longDir]
        });
      }
    };

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;

      console.info("binaryStr", binaryStr);

      handleUploadLogo(logoInputField.detailID, binaryStr, callBack);

      dispatch(
        setPersonalizedQuestionsProgressAction({
          logo: { ...progressState.logo, uploadedLogo: binaryStr }
        })
      );
    };
    reader.readAsDataURL(newFile);
  }, []);

  useEffect(() => {
    return () => {
      setInputsState({
        [logoInputField.detailID]: null
      });
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className={classes.wrapper}>
      {/*  <p className={classes.contact}>
        Need Help? <a href="#">Chat</a> or Call:{" "}
        <a href="tel:+1800-555-5555">1-800-555-55-55</a>
      </p> */}
      <div
        className={`${classes.dropZone}${
          uploadedLogo ? " " + classes.haveLogo : ""
        }`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Déposez les fichiers ici ...</p>
        ) : !uploadedLogo ? (
          <p>Glissez-déposez ou cliquez ici pour télécharger un logo</p>
        ) : (
          <React.Fragment>
            <img
              className={classes.logo}
              src={uploadedLogo}
              alt="uploaded logo"
            />
            <i
              className={`${classes.logoRemove} material-icons`}
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  setPersonalizedQuestionsProgressAction({
                    logo: { ...progressState.logo, uploadedLogo: null }
                  })
                );
              }}
            >
              close
            </i>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
