import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LINK_DISTRIBUTION } from "../../../../../project-config";
import { handleSelectedInkColor } from "../utils/handleSelectedInkColor";
import { handleSelectedLogoOption } from "../utils/logoSelectionHandler";
import classes from "./RadioButtonGroup.module.css";

export default function RadioButtonGroup({
  progressState,
  state,
  setState,
  buttons,
  label,
  name,
  type,
  firstOptionIsCheckedByDefault,
  dispatch,
  dependedFields,
  InkColorQuestion,
  needsCheckboxActivation,
  setValidations,
  failedValidations,
  buttonRef,
  lastIndex,
  setTempRef,
  tempRef
}) {
  const firstTempRef = useRef(null);
  const lastTempRef = useRef(null);

  useLayoutEffect(() => {
    console.log("SHIFT KEY START");
    if (progressState.progress === 3 && firstTempRef && firstTempRef.current) {
      setTempRef({ first: firstTempRef, last: lastTempRef });
    } else if (
      progressState.progress === 2 &&
      lastIndex.ind === lastIndex.len - 1
    ) {
      console.log("SHIFT KEY HEY", firstTempRef.current);
      setTempRef({ ...tempRef, last: lastTempRef });
    } else if (progressState.progress === 2 && lastIndex.ind === 0) {
      console.log("SHIFT KEY HEY", firstTempRef.current);
      setTempRef({ first: firstTempRef, ...tempRef });
    }
  }, [progressState]);

  useEffect(() => {
    console.info("SHIFT KEY CRAZY", tempRef);
  }, [tempRef]);

  const [active, setActive] = useState(!needsCheckboxActivation);

  const [validationMessage, setValidationMessage] = useState(null);
  useEffect(() => {
    console.info("failedValidations", failedValidations);
    if (failedValidations && failedValidations.validationType === "required") {
      console.info("failedValidations33", failedValidations[name]);

      setValidationMessage("Required");
    } else {
      setValidationMessage(null);
    }
  }, [failedValidations]);

  useEffect(() => {
    const previouslyChecked = buttons.find(b => b.answer === "true");
    console.info("previouslyChecked", buttons, previouslyChecked);
    if (previouslyChecked) {
      setState({ [name]: previouslyChecked.id });
      handleSelectedLogoOption(
        previouslyChecked.id,
        label,
        buttons,
        dispatch,
        progressState
      );

      handleSelectedInkColor(
        previouslyChecked.id,
        label,
        buttons,
        dispatch,
        progressState
      );
      return;
    }
    if (
      buttons &&
      buttons[0] &&
      firstOptionIsCheckedByDefault &&
      !needsCheckboxActivation
    ) {
      handleSelectedLogoOption(
        buttons[0].id,
        label,
        buttons,
        dispatch,
        progressState
      );

      handleSelectedInkColor(
        buttons[0].id,
        label,
        buttons,
        dispatch,
        progressState
      );

      setState({ [name]: buttons[0].id });
    }
  }, []);

  const handleOnChange = (e, key, id) => {
    let value = null;
    let name = null;
    if (e) {
      value = e.target.value;
      name = e.target.name;
    }
    handleSelectedLogoOption(
      value || id,
      label,
      buttons,
      dispatch,
      progressState,
      setState,
      dependedFields
    );
    handleSelectedInkColor(
      value || id,
      label,
      buttons,
      dispatch,
      progressState
    );
    setState({ [name || key]: value || id });
  };

  const handleCheckboxChanged = e => {
    const { checked } = e.target;
    console.info("checked", e.target.checked);
    setActive(checked);

    setValidations({ [name]: checked ? "required" : null });

    if (!checked) {
      let value = "";
      handleSelectedLogoOption(
        value,
        label,
        buttons,
        dispatch,
        progressState,
        setState,
        dependedFields
      );

      handleSelectedInkColor(value, label, buttons, dispatch, progressState);
      setState({ replace: true, key: name });
    }
  };

  const renderSelectedFontLabel = () => {
    if (InkColorQuestion)
      return (
        <span className={classes.selectedFontLabel}>
          {progressState && progressState.font && progressState.font[label]}
        </span>
      );
  };

  return (
    <div
      id={name}
      className={`${classes.wrapper}${
        validationMessage ? " " + classes.inputValidationFailed : ""
      }`}
    >
      {label && (
        <p>
          {needsCheckboxActivation && (
            <input
              id={label}
              checked={active}
              onChange={handleCheckboxChanged}
              type="checkbox"
            />
          )}
          <label htmlFor={label}>
            {label}:&nbsp;{renderSelectedFontLabel()}
          </label>
        </p>
      )}
      {buttons.map((btn, index) => {
        return (
          <div
            key={btn.id}
            className={`${classes.radioBtnWrapper}${
              type ? " " + classes[type] : ""
            }${InkColorQuestion ? " " + classes.inkColor : ""}${
              state[name] === btn.id ? " " + classes.active : ""
            }`}
          >
            <input
              disabled={!active}
              checked={state[name] && state[name] === btn.id}
              className={`${classes.radioBtn}`}
              onChange={handleOnChange}
              type="radio"
              id={btn.id}
              name={name}
              value={btn.id}
              onKeyDown={e => {
                if (
                  lastIndex.len - 1 === lastIndex.ind &&
                  e.keyCode === 9 &&
                  !e.shiftKey
                ) {
                  e.preventDefault();
                  buttonRef.current.focus();
                }
              }}
              hey={
                index === 0 &&
                btn.linesLength.ind === 0 &&
                progressState.progress === 2
                  ? "first"
                  : lastIndex.len - 1 === lastIndex.ind && index === 0
                  ? "last"
                  : "other"
              }
              ref={
                index === 0 &&
                btn.linesLength.ind === 0 &&
                progressState.progress === 2
                  ? firstTempRef
                  : lastIndex.len - 1 === lastIndex.ind
                  ? lastTempRef
                  : null
              }
            />
            {type === "color" && InkColorQuestion && (
              <React.Fragment>
                <img
                  className={active ? "" : classes.disabled}
                  onClick={() => {
                    if (active) handleOnChange(null, name, btn.id);
                  }}
                  title={active ? btn && btn.label : "Disabled"}
                  src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                  alt={btn && btn.label}
                ></img>
              </React.Fragment>
            )}
            {type === "color" && !InkColorQuestion && (
              <React.Fragment>
                <img
                  src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                  alt={btn && btn.label}
                ></img>
              </React.Fragment>
            )}
            {type === "image" && (
              <img
                src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                alt={btn && btn.label}
              ></img>
            )}
            {type !== "image" && (
              <label
                className={btn.label !== "" ? "" : "sr-only"}
                htmlFor={btn.id}
              >
                {btn.label !== ""
                  ? btn.label
                  : btn.altLabel
                      .replace("store/20060723001/assets/images/", "")
                      .replace(".gif", "")}
              </label>
            )}
          </div>
        );
      })}
      {validationMessage && (
        <p className={classes.validationMessage}>{validationMessage}</p>
      )}
    </div>
  );
}
