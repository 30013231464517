import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../assets/css/product-sl.css";
import "./Styles/OFBProductPage.css";

import ImageCarousel from "./Components/ImageCarousel/BDImageCarousel";
import ReviewBar from "./Components/Review/ReviewBar";
import ReviewBarMobile from "./Components/Review/ReviewBarMobile";
import Attributes from "./Components/Attributes/BDAttributes";
import AddToCartBox from "./Components/AddToCart/AddToCartBox";
import AddedToCartModal from "./Components/AddToCart/Components/AddedToCartModal";
import Reviews from "./Components/Review/components/Reviews";

import htmldecoder, { htmlDecode } from "../../functions/htmldecoder";
import CheckBoxAddons from "./Components/CheckBoxAddons/CheckBoxAddons";
import ShareButtons from "./Components/ShareButtons/ShareButtons";
import ShareIcon from "@material-ui/icons/Share";
import WishListBar from "./Components/WishList/WishListBar";
import ItemCode from "./Components/ItemCode/ItemCode";
import OtherInfoTab from "./Components/OtherInfoTab/OtherInfoTab";

import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import { I18nContext } from "../../i18n/index";
import { navigate, useLocation } from "@reach/router";
import {
  fetchDirectUrlGetItem,
  getSupplierInfo,
  mapProductUrls
} from "../../redux/actions/productAction";
import ConfirmDeliveryOptions from "../AC-ProductPage/Components/AddToCart/Components/ConfirmDeliveryOptions/ConfirmDeliveryOptions";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import OutOfStockError from "../AC-ProductPage/Components/CartValidationErrors/OutOfStockError";

import fbLogo from "../../assets/img/sellerFB.png";
import twLogo from "../../assets/img/sellerTW.png";
import igLogo from "../../assets/img/sellerIG.png";
import ptLogo from "../../assets/img/sellerPT.png";
import { setGoBackToCategoryFromProductPage } from "../../redux/actions/categoryActions";
import { isArray } from "../../functions/Utilities";
import { LINK_DISTRIBUTION } from "../../project-config";

const OFBProductPage = ({ data, storeInfo, dynamic }) => {
  console.info("storeinfo data", data, storeInfo);
  const { translate, langCode } = React.useContext(I18nContext);
  const location = useLocation();

  const dispatch = useDispatch();

  const [titleState, setTitleState] = useState("");
  const [enquiryModalState, setEnquiryModalState] = useState(false);

  const [supplierName, setSupplierName] = React.useState("");
  const [supplierCity, setSupplierCity] = React.useState("");

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const ItemCodeState = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  const recentViewItemsState = useSelector(
    state => state.recentlyViewedItemsReducer.recentlyViewedItemsList,
    shallowEqual
  );

  const itemDetailIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const productInitialStateFromFetch = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const supplierInfoReducer = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );
  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  React.useEffect(() => {
    if (!data && supplierInfoReducer.length > 0) {
      if (supplierInfoReducer[0].distributorOrder.length > 0) {
        setSupplierName(supplierInfoReducer[0].distributorOrder[0].name);
        setSupplierCity(supplierInfoReducer[0].distributorOrder[0].city);
      }
    }
  }, [supplierInfoReducer]);

  useEffect(() => {
    console.info("RENDER++", itemDetailState.code);
    if (typeof window !== undefined && itemDetailIdState) {
      //  dispatch(getSupplierInfo(itemDetailIdState));
      window.scrollTo(0, 0);
    }
  }, [itemDetailIdState]);

  useEffect(() => {
    if (!data && productInitialStateFromFetch.title != "") {
      /*  if (productInitialState != "") {
        setTitleState(itemDetailsTitleState);
      } else { */
      setTitleState(productInitialStateFromFetch.title);
      /*       } */
    }
  }, [productInitialStateFromFetch /* , itemDetailsTitleState */]);

  const productUnavailableState = useSelector(
    state => state.productReducer.productUnavilable,
    shallowEqual
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "share-popover" : undefined;

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const isLoggedInState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [swipeLoc, setSwipeLoc] = React.useState({ start: 0, end: 0 });

  const reviewsContainerRef = useRef();

  console.info("borop data", data, storeInfo);

  const [count, setCount] = useState(0);

  const [starFilter, setStarFilter] = useState([]);
  const [avgRating, setAvgRating] = useState({
    avgRating: 0,
    countOfEachStar: {},
    totalValue: 0
  });

  const [reviewsWithImages, setReviewsWithImages] = useState([]);

  useEffect(() => {
    if (typeof window !== undefined) window.scrollTo(0, 0);

    //dispatch(getSupplierInfo(data._0.id));
    dispatch(setGoBackToCategoryFromProductPage(true));

    return () => {
      dispatch(setGoBackToCategoryFromProductPage(false));
    };
  }, []);

  useEffect(() => {
    if (count == 0 && typeof localStorage !== undefined) {
      setCount(count + 1);
      let languageStorage = localStorage.getItem("language");
      if (!languageStorage) {
        localStorage.setItem("language", langCode);
        languageStorage = "en";
      }
      let countryStorage = localStorage.getItem("country");
      if (!countryStorage) {
        countryStorage = "en";
        localStorage.setItem("country", countryStorage);
      }
      let url = location.pathname;
      if (url[url.length - 1] === "/") url = url.substr(0, url.length - 1);

      const callback = res => {
        console.info("callback product", res);
        if (res && res.message && res.message.includes("ERROR")) {
          if (res.message.includes("404")) {
            navigate("/404");
          } else navigate("/");
        }
      };

      if (url.includes(`/${localStorage.getItem("language")}/`) === false) {
        dispatch(
          fetchDirectUrlGetItem(
            url,
            localStorage.getItem("language"),
            countryStorage,
            data ? data._0.id : null,
            callback
          )
        );
      } else {
        setCount(count + 1);
        dispatch(
          fetchDirectUrlGetItem(url, langCode, countryStorage, null, callback)
        );
      }
    }

    return () => {
      setCount(0);
    };
  }, [location.pathname, langCode]);

  const buttonRef = useRef();

  /*   useEffect(() => {
    var cartBox = document.getElementById("pickupShipInfo");
    cartBox.innerHTML = "";
    var tempPickupHTML = "";
    var tempShipHTML = "";

    console.log("cartBox", cartBox, storeInfo);

    if (storeInfo.pickupLocData && storeInfo.pickupLocData) {
      tempPickupHTML =
        "<div class='pickupShipInfo'><h2>Pickup Locations</h2><div class='pickupShipInfoBody pickup'>";
      if (!isArray(storeInfo.pickupLocData))
        storeInfo.pickupLocData = [{ ...storeInfo.pickupLocData }];
      for (var x = 0; x < storeInfo.pickupLocData.length; x++) {
        tempPickupHTML =
          tempPickupHTML +
          "<p class='pickupDataBox'><span>" +
          storeInfo.pickupLocData[x].pickup_location_name +
          "</span><br/>" +
          storeInfo.pickupLocData[x].address_place +
          " <br/> <a target='_blank' href='https://www.google.com/maps/dir/here/" +
          storeInfo.pickupLocData[x].latitude +
          "," +
          storeInfo.pickupLocData[x].longitude +
          "'>Get Directions</a>";

        if (storeInfo.pickupLocData[x].time != null) {
          tempPickupHTML =
            tempPickupHTML +
            "<br/><span>Hours:</span> " +
            storeInfo.pickupLocData[x].time;
        }

        if (storeInfo.pickupLocData[x].additional_information != null) {
          tempPickupHTML =
            tempPickupHTML +
            "<br/><span>Additional Info:</span> " +
            storeInfo.pickupLocData[x].additional_information;
        }

        tempPickupHTML = tempPickupHTML + "</p>";
      }

      tempPickupHTML = tempPickupHTML + "</div></div>";
    }

    if (
      storeInfo.supplierShippingInfo &&
      storeInfo.supplierShippingInfo.length > 0 &&
      storeInfo.supplierShippingInfo[0].shipping_type != 0
    ) {
      tempShipHTML =
        "<div class='pickupShipInfo'><h2>Shipping Info</h2><div class='pickupShipInfoBody'>";

      var tempShipHTML = tempShipHTML + "<p><span>Ships:</span> ";

      if (storeInfo.supplierShippingInfo[0].shipping_type == 1) {
        tempShipHTML = tempShipHTML + "Worldwide";
      } else if (storeInfo.supplierShippingInfo[0].shipping_type == 2) {
        tempShipHTML = tempShipHTML + "Within Canada";
      } else if (storeInfo.supplierShippingInfo[0].shipping_type == 3) {
        if (storeInfo.storeSellerData.province) {
          tempShipHTML =
            tempShipHTML + "Within " + storeInfo.storeSellerData.province;
        } else {
          tempShipHTML = tempShipHTML + "Within Province";
        }
      } else if (storeInfo.supplierShippingInfo[0].shipping_type == 4) {
        tempShipHTML =
          tempShipHTML +
          "Within " +
          storeInfo.supplierShippingInfo[0].range +
          "KM";
      }

      tempShipHTML = tempShipHTML + "</p>";

      if (
        storeInfo.supplierShippingInfo[0].instructions &&
        storeInfo.supplierShippingInfo[0].instructions != null
      ) {
        tempShipHTML =
          tempShipHTML +
          "<p><span>Additional Info:</span> " +
          storeInfo.supplierShippingInfo[0].instructions +
          "</p>";
      }

      tempShipHTML = tempShipHTML + "</div></div>";
    }

    cartBox.innerHTML = cartBox.innerHTML + tempPickupHTML;
    cartBox.innerHTML = cartBox.innerHTML + tempShipHTML;
  }, []); */

  /*let imageUrl = storeInfo.storeSellerData.image;
  if (imageUrl.includes("sls3.avetti.ca")) {
    imageUrl = imageUrl.replace(
      "sls3.avetti.ca",
      "demob2b2cs3.avetti.ca/store"
    );
  }*/

  var mapsLink;
  if (
    storeInfo &&
    storeInfo.storeSellerData &&
    Object.keys(storeInfo.storeSellerData).includes("location") &&
    storeInfo.storeSellerData.location
  ) {
    mapsLink =
      "https://www.google.ca/maps/search/" + storeInfo.storeSellerData.location;
  } else {
    mapsLink = "";
  }

  const socialRef = useRef(null);
  const imageCaroRef = useRef(null);
  const sectionRef = useRef(null);
  return (
    <React.Fragment>
      {/*  <EnquiryModal
        isLoggedIn={loginNameState !== ""}
        enquiryModalState={enquiryModalState}
        setEnquiryModalState={setEnquiryModalState}
      /> */}
      {/* <AccessoryModal /> */}

      <div id="bd" className="item">
        <Breadcrumb back={true} />
        <div className="col s12">
          <div style={{ marginTop: "5px" }}>
            <div className="item-main">
              <div id="mainGridDiv" className="item-top-row row">
                {isMobileState ? (
                  <React.Fragment>
                    <p className="brandItem">
                      <span id="buyBoxDistName">
                        {data &&
                        data.supplierData &&
                        data.supplierData._xResult &&
                        data.supplierData._xResult.length > 0 &&
                        data.supplierData._xResult[0].distributorOrder &&
                        data.supplierData._xResult[0].distributorOrder.length >
                          0 &&
                        data.supplierData._xResult[0].distributorOrder[0].name
                          ? data.supplierData._xResult[0].distributorOrder[0]
                              .name
                          : ""}
                      </span>
                    </p>

                    <ReviewBarMobile
                      avgRating={avgRating}
                      starFilter={starFilter}
                      setStarFilter={setStarFilter}
                      reviewsContainerRef={reviewsContainerRef}
                    />

                    <div
                      id="js-item-title-267578"
                      className="regularTitle title itemTitle itemTitleMobile"
                      dangerouslySetInnerHTML={{
                        __html: htmldecoder(
                          data
                            ? data.productData._xResult[0].title
                            : itemDetailState && itemDetailState.title
                        )
                      }}
                    ></div>
                  </React.Fragment>
                ) : null}

                <div id="leftGrid">
                  <div id="titleGrid">
                    {!isMobileState ? (
                      <React.Fragment>
                        <div
                          style={{ marginBottom: "10px" }}
                          id="js-item-title-267578"
                          className="regularTitle title itemTitle itemTitleMobile"
                          dangerouslySetInnerHTML={{
                            __html: htmldecoder(
                              data
                                ? data.productData._xResult[0].title
                                : itemDetailState && itemDetailState.title
                            )
                          }}
                        ></div>
                        {/*    <ReviewBar
                          avgRating={avgRating}
                          starFilter={starFilter}
                          setStarFilter={setStarFilter}
                          reviewsContainerRef={reviewsContainerRef}
                        /> */}
                      </React.Fragment>
                    ) : null}
                    {/*     {!isMobileState && isLoggedInState ? (
                      <React.Fragment>
                        <WishListBar
                          data={data}
                          productUnavailable={productUnavailableState}
                          price={data.productData._xResult[0].prices[0].price_1}
                        />
                      </React.Fragment>
                    ) : null} */}
                    <ItemCode
                      code={
                        data ? data.productData._xResult[0].code : ItemCodeState
                      }
                    />
                    <div
                      style={{ display: "none" }}
                      className="col-xs-12 product-page-seller-info"
                    >
                      {!isMobileState ? (
                        <React.Fragment>
                          {/* <div className="inStock-container">
                            {priceState && priceState.invs && priceState.invs[0] ? (
                              <div className="inStock-wrapper">
                                <label>{translate("js.item.stock")}:</label>
                                &nbsp;
                                <span style={{ fontWeight: "400" }}>
                                  {priceState.invs[0].instock}
                                </span>
                              </div>
                            ) : null}
                          </div> */}

                          <p>
                            <strong>{translate("js.item.available")}: </strong>
                            <span
                              id="buyBoxDistName"
                              style={{ fontWeight: "400" }}
                            >
                              {data &&
                              data.supplierData &&
                              data.supplierData._xResult &&
                              data.supplierData._xResult.length > 0 &&
                              data.supplierData._xResult[0].distributorOrder &&
                              data.supplierData._xResult[0].distributorOrder
                                .length > 0 &&
                              data.supplierData._xResult[0].distributorOrder[0]
                                .name
                                ? data.supplierData._xResult[0]
                                    .distributorOrder[0].name
                                : ""}
                            </span>
                          </p>
                        </React.Fragment>
                      ) : null}
                      <p>
                        <strong>{translate("js.item.location")}: </strong>
                        <span id="buyBoxDistLoc" style={{ fontWeight: "400" }}>
                          {data &&
                          data.supplierData &&
                          data.supplierData._xResult &&
                          data.supplierData._xResult.length > 0 &&
                          data.supplierData._xResult[0].distributorOrder &&
                          data.supplierData._xResult[0].distributorOrder
                            .length > 0 &&
                          data.supplierData._xResult[0].distributorOrder[0].city
                            ? data.supplierData._xResult[0].distributorOrder[0]
                                .city
                            : ""}
                        </span>
                      </p>

                      <div className="product-page-seller-rating">
                        <strong>
                          {translate("js.item.sellerrating")}:&nbsp;
                        </strong>
                        <div id="buyBoxDistRate">
                          <div className="dist-item-rating">
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <div
                              style={{ display: "none" }}
                              className="distReviewCount"
                            >
                              0 {translate("js.item.reviews")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ImageCarousel
                    productUnavailable={productUnavailableState}
                    giftcard="false"
                    data={{
                      productCode: data
                        ? data.productData._xResult[0].code
                        : itemDetailState.code,
                      itemId: data ? data._0.id : itemDetailState.itemId,
                      hiddenProperties: data
                        ? data.productData._xResult[0].hiddenProperties
                        : itemDetailState.hiddenProperties,
                      title: data
                        ? data.productData._xResult[0].title
                        : itemDetailState.title,
                      url: data ? data.url : ""
                    }}
                    title={
                      data
                        ? data.productData._xResult[0].title
                        : itemDetailState && itemDetailState.title
                    }
                    /*      imageUrl={data.productData._xResult[0].image.replace(
                      "images",
                      "largeimages"
                    )} */
                    imageCaroRef={imageCaroRef}
                  />
                </div>
                <div id="rightGrid">
                  <CheckBoxAddons />

                  {true ? (
                    <AddToCartBox
                      data={data}
                      productUnavailable={productUnavailableState}
                      giftcard="false"
                      prices={
                        data
                          ? data.productData._xResult[0].prices
                          : itemDetailState && itemDetailState.prices
                      }
                      title={htmlDecode(
                        data
                          ? data.productData._xResult[0].title
                          : itemDetailState && itemDetailState.title
                      )}
                      socialRef={socialRef}
                      buttonRef={buttonRef}
                      imageCaroRef={imageCaroRef}
                      sectionRef={sectionRef}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        const btn = document.getElementById("login-icon-btn");
                        if (btn) btn.click();
                      }}
                      onKeyPress={e => {
                        e.preventDefault();
                        const btn = document.getElementById("login-icon-btn");
                        if (btn) btn.click();
                      }}
                      tabIndex="0"
                      className="cart-box-login-prompt"
                    >
                      Login to See Pricing
                    </div>
                  )}
                </div>
                <ConfirmDeliveryOptions />
                <CartValidationErrors />
                <OutOfStockError />
                <AddedToCartModal />
              </div>
            </div>
          </div>
        </div>
        {/* <Accessories /> */}
        {/* <VendorList
          handleEnquiryModalOpenClicked={handleEnquiryModalOpenClicked}
        /> */}
        <div className="product-details-specs-container">
          {!isMobileState && (
            <ShareButtons
              socialRef={socialRef}
              title={
                data
                  ? data.productData._xResult[0].title
                  : itemDetailState.title && itemDetailState.title
              }
              imageUrl={`${LINK_DISTRIBUTION}/${
                itemDetailState && itemDetailState.image
              }`}
              buttonRef={buttonRef}
              isLoggedInState={isLoggedInState}
            />
          )}
        </div>
        <OtherInfoTab
          longDesc={{
            longdesc: data
              ? data.productData._xResult[0].longdesc
              : itemDetailState && itemDetailState.longdesc,
            longdesc2: data
              ? data.productData._xResult[0].longdesc2
              : itemDetailState && itemDetailState.longdesc2,
            longdesc3: data
              ? data.productData._xResult[0].longdesc3
              : itemDetailState && itemDetailState.longdesc3,
            longdesc4: data
              ? data.productData._xResult[0].longdesc4
              : itemDetailState && itemDetailState.longdesc4
          }}
          properties={
            data
              ? data.productData._xResult[0].properties
              : itemDetailState && itemDetailState.properties
          }
        />

        <div className="clearfix"></div>
        {/*storeInfo.storeSellerData && (
          <div className="storeInfoWrapper itemStoreInfo">
            <div className="storeLogo">{<img src={imageUrl}></img>}</div>
            <div className="storeInfoMain">
              <div className="storeInfo1">
                <h1 className="storeName testClass">
                  {storeInfo.storeSellerData && storeInfo.storeSellerData.brand}
                </h1>
                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("year") &&
                storeInfo.storeSellerData.year != "" ? (
                  <p>
                    <span>
                      Established{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "year"
                        ) &&
                        storeInfo.storeSellerData.year}{" "}
                      -{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "city"
                        ) &&
                        storeInfo.storeSellerData.city}
                      ,{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "province"
                        ) &&
                        storeInfo.storeSellerData.province}
                    </span>
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes(
                  "phone_number"
                ) &&
                storeInfo.storeSellerData.phone_number != "" ? (
                  <p>
                    <span>Phone:</span>{" "}
                    {storeInfo.storeSellerData &&
                      Object.keys(storeInfo.storeSellerData).includes(
                        "phone_number"
                      ) &&
                      storeInfo.storeSellerData.phone_number}
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("location") &&
                storeInfo.storeSellerData.location != "" ? (
                  <p>
                    <span>Address:</span>{" "}
                    {storeInfo.storeSellerData &&
                      Object.keys(storeInfo.storeSellerData).includes(
                        "location"
                      ) &&
                      storeInfo.storeSellerData.location}
                    <br />
                    {mapsLink != "" ? (
                      <a className="mapsLink" target="_blank" href={mapsLink}>
                        View on Google Maps
                      </a>
                    ) : null}
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("website") &&
                storeInfo.storeSellerData.website != null ? (
                  <p>
                    <span>Website:</span>{" "}
                    {storeInfo.storeSellerData.website}
                  </p>
                ) : null}
                <p className="sellerSocialLine">
                  {storeInfo.storeSellerData !== undefined &&
                  (Object.keys(storeInfo.storeSellerData).includes(
                    "facebook"
                  ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "twitter"
                    ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "instagram"
                    ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "pinterest"
                    )) &&
                  (storeInfo.storeSellerData.facebook != null ||
                    storeInfo.storeSellerData.twitter != null ||
                    storeInfo.storeSellerData.instagram != null ||
                    storeInfo.storeSellerData.pinterest != null) ? (
                    <span>
                      <span>Connect With Us:</span>
                      <br />
                    </span>
                  ) : null}

                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes("facebook") &&
                  storeInfo.storeSellerData.facebook != null ? (
                    <a href={storeInfo.storeSellerData.facebook}>
                      <img className="sellerSocialLogo" src={fbLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes("twitter") &&
                  storeInfo.storeSellerData.twitter != null ? (
                    <a href={storeInfo.storeSellerData.twitter}>
                      <img className="sellerSocialLogo" src={twLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes(
                    "instagram"
                  ) &&
                  storeInfo.storeSellerData.instagram != null ? (
                    <a href={storeInfo.storeSellerData.instagram}>
                      <img className="sellerSocialLogo" src={igLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes(
                    "pinterest"
                  ) &&
                  storeInfo.storeSellerData.pinterest != null ? (
                    <a href={storeInfo.storeSellerData.pinterest}>
                      <img className="sellerSocialLogo" src={ptLogo} />
                    </a>
                  ) : null}
                </p>
              </div>
              <div className="storeInfo2">
                <h2 className="storeName">About Us</h2>

                <div id="aboutInfo">
                  <p>
                    {storeInfo.storeSellerData &&
                      storeInfo.storeSellerData.description &&
                      storeInfo.storeSellerData.description.blocks &&
                      storeInfo.storeSellerData.description.blocks[0] &&
                      storeInfo.storeSellerData.description.blocks[0].text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )*/}
        <Reviews
          avgRating={avgRating}
          setAvgRating={setAvgRating}
          starFilter={starFilter}
          setStarFilter={setStarFilter}
          reviewsContainerRef={reviewsContainerRef}
          reviewsWithImages={reviewsWithImages}
          setReviewsWithImages={setReviewsWithImages}
        />
        {/* <RecentlyViewedItems /> */}
      </div>
    </React.Fragment>
  );
};

export default OFBProductPage;
