import React from "react";
import classes from "./Button.module.css";

export default function Button({
  primary,
  title,
  onClick,
  children,
  hidden,
  tabIndex
}) {
  let btnClass = classes.btn + " ";

  if (primary) btnClass += classes.primary;
  else btnClass += classes.default;

  return (
    <button
      tabIndex={tabIndex}
      style={{ visibility: hidden ? "hidden" : null }}
      onClick={onClick}
      title={title}
      className={btnClass}
    >
      {children}
    </button>
  );
}
