import React, { useEffect, useRef, useState, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  getDeliveryOptions,
  addToLocalMiniCart,
  setAddToCartValidationErrors,
  setProductOutOfStockError,
  addToCartProduct,
  setPersonalizedQuestionsProgressAction,
  showContinueModalAfterAddingToCartAction,
  setChequeValidationErrors,
  reFetchProductInitialState,
  fetchingProductRequestSaga,
  requestPersonalizedQuestionsAction,
  setPersonalizedQuestionsAction,
  addToCartCheckboxProduct
} from "../../../../redux/actions/productAction";
import { SECTION_BUTTONS } from "../../../AC-ProductPage/Components/ChequeCustomization/data/section-buttons";
import {
  addToCartActions,
  REQUEST_BASKET_AFTER_ADDING_TO_CART
} from "../../../../redux/types";
import { I18nContext } from "../../../../i18n/index";
import { FormattedNumber } from "react-intl";
//import NotificationRequest from "../../Components/NotificationRequest/NotificationRequest"
import Loading from "../../../AC-Loading/Loading";
import GrandTotalCheckBoxItems from "./Components/GrandTotalCheckBoxItems";
import NotifyOnReStock from "../../../AC-Notifications/NotifyOnReStock";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./Styles/AddToCartBox.css";
import WishListBarMobile from "../WishList/WishListBarMobile";
import postPersonalizedQuestions from "../../../AC-ProductPage/Components/ChequeCustomization/utils/postPersonalizedQuestions";

import Select from "../../../AC-ProductPage/Components/ChequeCustomization/components/Select";
import ChequeCustomization from "../../../AC-ProductPage/Components/ChequeCustomization/ChequeCustomization";
import Attributes from "../Attributes/BDAttributes";
import SectionButtons from "../../../AC-ProductPage/Components/ChequeCustomization/SectionButtons";
import Button from "../../../AC-ProductPage/Components/ChequeCustomization/components/Button";

const WIGGLE_TIMER = 2500;

const AddToCartBox = props => {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = useContext(I18nContext);

  const [activeSection, setActiveSection] = useState(
    SECTION_BUTTONS && SECTION_BUTTONS[0]
  );

  /* const [listPrice, setListPrice] = useState(
    props.priceInv.prices[0].listprice || 0
  ); */

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [displayPricingTable, setDisplayPricingTable] = useState(true);

  const [firstDistId, setFirstDistId] = useState(0);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    if (props.prices && props.prices[0] && props.prices[0].price_1) {
      setPrice(props.prices[0].price_1);
    }
  }, [props]);

  console.info("addtocart props", props);

  const [distributorId, setDistributorId] = useState(null);
  const [packagePrices, setPackagePrices] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({
    key: "selected quantity",
    value: -1,
    noPackage: false
  });

  const [productQuantityType, setProductQuantityType] = useState(null);

  /*   useEffect(() => {
    if (
      props.priceInv.prices.length > 0 &&
      Object.keys(props.priceInv.prices[0]).includes("price_1")
    ) {
      setPrice(props.priceInv.prices[0].price_1);
    }
  }, [props]); */

  const [numberOfItems, setNumberOfItems] = useState(1);
  const [shouldWiggle, setShouldWiggle] = useState(false);
  const [perUnitState, setPerUnitState] = useState("");
  const [inStock, setInStock] = useState(true);
  const [inStockQty, setInStockQty] = useState("");

  const initialValue = useRef(true);

  const checkBoxItemsState = useSelector(
    state => state.productReducer.checkboxItems,
    shallowEqual
  );

  const selectedCheckBoxItemsState = useSelector(
    state => state.productReducer.selectedCheckboxItems,
    shallowEqual
  );

  const personalizedQuestionInputsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.inputs,
    shallowEqual
  );

  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const personalizedQuestionsValidationsState = useSelector(
    state => state.productReducer.personalizedQuestionsValidations,
    shallowEqual
  );

  const supplierInfoState = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const pricesState = useSelector(
    state => state.productReducer.itemDetail.prices,
    shallowEqual
  );

  const invsState = useSelector(
    state => state.productReducer.itemDetail.invs,
    shallowEqual
  );

  const isProductDetailsLoading = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const attributesState = useSelector(
    state => state.productReducer.itemDetail.attributes,
    shallowEqual
  );

  const attributeid =
    attributesState && attributesState[0] && attributesState[0].attributeid;

  const productAttributeCheckboxFlagState = useSelector(
    state => state.productReducer.productAttributeCheckboxFlag,
    shallowEqual
  );

  const selectedProductCheckboxAttributesState = useSelector(
    state => state.productReducer.selectedProductCheckboxAttributes,
    shallowEqual
  );

  const requestingAddToCartState = useSelector(
    state => state.productReducer.requestingAddToCart,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const skusState = useSelector(
    state => state.productReducer.itemDetail.skus,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const packagePriceEnabledState = useSelector(
    state => state.productReducer.itemDetail.packagePriceEnabled,
    shallowEqual
  );
  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const personalizedItemState = useSelector(
    state => state.productReducer.itemDetail.personalized,
    shallowEqual
  );

  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const productInitialProperties = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  const itemCodeState = useSelector(
    state => state.productReducer.itemDetail.code,
    useSelector
  );

  useEffect(() => {
    if (isProductDetailsLoading) {
      setPerUnitState(null);
    } else {
      if (productInitialProperties && productInitialProperties.length > 0) {
        let perUnitProp = productInitialProperties.find(prop =>
          ["Per Unit", "Per-Unit"].includes(Object.keys(prop)[0])
        );
        console.info("perUnitProp", productInitialProperties, perUnitProp);
        if (perUnitProp) setPerUnitState(Object.values(perUnitProp)[0]);

        const quantityTypeProp = productInitialProperties.find(prop =>
          Object.keys(prop).includes("product_quantity_type")
        );

        if (quantityTypeProp)
          setProductQuantityType(quantityTypeProp.product_quantity_type);
      }
    }
  }, [isProductDetailsLoading, productInitialProperties]);

  const bundleItemsProgressState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.bundleItemsProgress,
    shallowEqual
  );

  const bundleItemsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.bundleItems,
    shallowEqual
  );
  const displayQuestionsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.displayQuestions,
    shallowEqual
  );

  const currentItemIsBundleItemState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress
        .currentItemIsBundleItem,
    shallowEqual
  );
  console.info("borop2 loading", isProductDetailsLoading, selectedPackage);
  const biidState = useSelector(
    state => state.productReducer.biid,
    shallowEqual
  );

  const basketState = useSelector(
    state => state.sessionReducer.basket,
    shallowEqual
  );

  useEffect(() => {
    if (isProductDetailsLoading) {
      setPerUnitState(null);
    } else {
      let prices = itemDetailState && itemDetailState.prices;
      let distributorId = prices && prices.distributorId;

      let price = prices && prices[0] && prices[0].price_1;
      let tempPackagePrices = [];

      //set package prices
      if (prices && prices.length > 0) {
        //sort  prices based on priority

        const numberItemCode = itemCode => {
          if (itemCode.match(/-\d/)) {
            return parseInt(itemCode.split("-")[1]);
          }
          return 0;
        };
        let sortedPrices = prices.sort((a, b) => {
          const aItemCode = numberItemCode(a.itemcode);
          const bItemCode = numberItemCode(b.itemcode);
          return aItemCode - bItemCode;
        });
        console.info("sortedPrices", sortedPrices, prices);

        const atleastOneDashedItemCodeExists = sortedPrices.some(p =>
          p.itemcode.match(/-\d/)
        );
        tempPackagePrices = sortedPrices.reduce((a, c) => {
          if (atleastOneDashedItemCodeExists) {
            if (c.packagePrices.length > 0 && c.itemcode.includes("-")) {
              const itemCodeNumber = numberItemCode(c.itemcode);
              console.info(
                "packagePrices and itemCodeNumber",
                c,
                c.packagePrices,
                c.priceLabel,
                itemCodeNumber
              );
              c.priceLabel = `${itemCodeNumber} ${
                itemCodeNumber > 1 ? "Copies" : "Copie"
              }`;
              a.push({ prices: c.packagePrices, label: c.priceLabel });
            }
          } else {
            if (c.packagePrices.length > 0)
              a.push({ prices: c.packagePrices, label: c.priceLabel });
          }

          return a;
        }, []);
      }

      if (distributorId) setDistributorId(distributorId);
      if (tempPackagePrices && tempPackagePrices.length > 0) {
        setPackagePrices(tempPackagePrices);
        setPrice(tempPackagePrices[0].prices[0].price);
        setSelectedPackage({
          ...selectedPackage,
          value: tempPackagePrices[0].prices[0].price,
          text: tempPackagePrices[0].prices[0].quantity
        });
      } else {
        setPrice(price);
        setSelectedPackage({
          ...selectedPackage,
          value: null,
          text: null,
          noPackage: true
        });
      }
      /*  if (tempPackagePrices) {
          setPackagePrices(tempPackagePrices);
        if (
          tempPackagePrices &&
          tempPackagePrices[0] &&
          tempPackagePrices[0].prices &&
          tempPackagePrices[0].prices.length > 1
        ) {
          setPrice(price);

          setSelectedPackage({
            ...selectedPackage,
            value: tempPackagePrices[0].prices[0].price,
            text: tempPackagePrices[0].prices[0].quantity
          });
        }
      } */
    }
  }, [isProductDetailsLoading]);

  useEffect(() => {
    if (invsState && invsState.length > 0 && itemCodeState) {
      // determine stock status
      setInStock(invsState.find(inv => inv.code === itemCodeState)?.instock);
    }
  }, [invsState, itemCodeState]);

  useEffect(() => {
    let timer = null;
    if (numberOfItems) {
      if (initialValue.current) {
        initialValue.current = false;
        return;
      }

      timer = setTimeout(() => {
        setShouldWiggle(true);
      }, WIGGLE_TIMER);
    }
    return () => {
      setShouldWiggle(false);
      clearTimeout(timer);
    };
  }, [numberOfItems]);

  const handleAddToCart = (quoteMode = false) => {
    if (!inStock) {
      dispatch(setProductOutOfStockError(true));
      return;
    }
    let attributesObject = null;

    let requiredFields = [];

    if (productAttributeCheckboxFlagState) {
      let expectedAttributeIdAndScreenName = "";
      if (attributeDetailsState && attributeDetailsState.length > 0) {
        expectedAttributeIdAndScreenName = attributeDetailsState[0];
      }

      let hasSelectedAttribute =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[
          expectedAttributeIdAndScreenName.attributeid
        ] &&
        selectedProductCheckboxAttributesState[
          expectedAttributeIdAndScreenName.attributeid
        ].length > 0;

      if (!hasSelectedAttribute)
        requiredFields.push(expectedAttributeIdAndScreenName.screenname);
    } else {
      if (
        selectedProductAttributesState.hasOwnProperty(
          mainItemIdState || itemIdState
        )
      ) {
        attributesObject =
          selectedProductAttributesState[mainItemIdState || itemIdState];

        let expectedAttributes = attributeDetailsState.reduce((p, c) => {
          const { screenname, attributeid, skuEnabled } = c;
          p = [...p, { screenname, attributeid, skuEnabled }];
          return p;
        }, []);

        let receivedAttributeIds = Object.keys(attributesObject);

        console.info(
          "addToCartTest",
          attributesObject,
          expectedAttributes,
          receivedAttributeIds
        );

        if (mainItemIdState != 0) {
          expectedAttributes.forEach(attr => {
            if (!receivedAttributeIds.includes(attr.attributeid.toString()))
              requiredFields.push(attr.screenname);
          });
        } else {
          expectedAttributes.forEach(attr => {
            if (attr.skuEnabled) requiredFields.push(attr.screenname);
          });
        }
      } else {
        if (attributeDetailsState && attributeDetailsState.length > 0) {
          let expectedAttributes = attributeDetailsState.reduce((p, c) => {
            const { screenname, attributeid } = c;
            p = [...p, { screenname, attributeid }];
            return p;
          }, []);

          expectedAttributes.forEach(attr => {
            requiredFields.push(attr.screenname);
          });
        }
      }
    }

    dispatch(setAddToCartValidationErrors(requiredFields));

    if (requiredFields.length > 0) {
      return;
    }
    let firstDist =
      supplierInfoState &&
      supplierInfoState[0] &&
      supplierInfoState[0].distributorOrder &&
      supplierInfoState[0].distributorOrder[0];

    let vid = firstDist && firstDist.supplier_store_vid;

    if (itemDetailState.personalized) {
      if (
        bundleItemsState &&
        bundleItemsState.length > 0 &&
        currentItemIsBundleItemState === false
      ) {
        dispatch(
          setPersonalizedQuestionsProgressAction({
            displayBundleItemsMessage: true
          })
        );
      }

      console.info(
        "cheque initial6",
        currentItemIsBundleItemState,
        bundleItemsState
      );
      if (currentItemIsBundleItemState === true && bundleItemsState) {
        requestPersonalizedQuestionsAction(dispatch, itemDetailState.itemid);
        return;
      }

      let invhistid = "";
      dispatch(
        addToCartProduct(
          itemDetailState.itemid,
          packagePriceEnabledState ? selectedPackage.text : numberOfItems,
          invhistid,
          distributorId,
          attributesObject,
          quoteMode,
          null,
          itemDetailState.personalized,
          false,
          null,
          false,
          null,
          biidState
        )
      );
    } else if (bundleItemsState && bundleItemsState.length > 0) {
      let invhistid = "";
      dispatch(
        addToCartProduct(
          itemDetailState.itemid,
          packagePriceEnabledState ? selectedPackage.text : numberOfItems,
          invhistid,
          distributorId,
          attributesObject,
          quoteMode,
          null,
          false,
          true,
          bundleItemsState,
          currentItemIsBundleItemState,
          bundleItemsProgressState
        )
      );
    }

    if (
      productAttributeCheckboxFlagState &&
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length === 0
    ) {
      let itemCount =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[attributeid] &&
        selectedProductCheckboxAttributesState[attributeid].length;

      let priceInventory =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState.priceInventory;
      console.info("borop3", selectedProductAttributesState);
      let products = [];

      let keys = Object.keys(priceInventory).filter(key =>
        selectedProductCheckboxAttributesState[attributeid].includes(
          Number(key)
        )
      );

      let outOfStockAll = true;
      for (let key of keys) {
        let product = {};
        product.attributes = [attributeid, key];
        product.optionId = key;
        product.id = priceInventory[key].itemid;
        product.distributorId = priceInventory[key].invs[0].distributorId;
        product.qty = priceInventory[key].qty;

        //if out of stock don't add to the list of products and reduce the number of selected items
        if (priceInventory[key].invs[0].instock) {
          products.push(product);
          outOfStockAll = false;
        } else {
          itemCount -= 1;
        }
      }

      if (outOfStockAll) {
        dispatch(setProductOutOfStockError(true));
        return;
      }

      /*  let attributesObject = {};

      attributesObject = selectedProductCheckboxAttributesState[
        attributeid
      ].map(optionid => {
        return [attributeid, optionid];
      }); */

      dispatch(addToCartCheckboxProduct(itemCount, products, 0, false, vid));
    } else if (
      productAttributeCheckboxFlagState &&
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length > 0
    ) {
      let itemCount =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[attributeid] &&
        selectedProductCheckboxAttributesState[attributeid].length;

      itemCount += selectedCheckBoxItemsState.length;

      let priceInventory =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState.priceInventory;
      console.info("borop3", selectedProductAttributesState);
      let products = [];

      let keys = Object.keys(priceInventory).filter(key =>
        selectedProductCheckboxAttributesState[attributeid].includes(
          Number(key)
        )
      );

      let outOfStockAll = true;
      for (let key of keys) {
        let product = {};
        product.attributes = [attributeid, key];
        product.optionId = key;
        product.id = priceInventory[key].itemid;
        product.distributorId = priceInventory[key].invs[0].distributorId;
        product.qty = priceInventory[key].qty;

        //if out of stock don't add to the list of products and reduce the number of selected items
        if (priceInventory[key].invs[0].instock) {
          products.push(product);
          outOfStockAll = false;
        } else {
          itemCount -= 1;
        }
      }

      // add the extras to the products
      selectedCheckBoxItemsState.forEach(item => {
        let product = {};
        product.id = item.id;
        product.distributorId = item.distId;
        product.qty = item.qty;
        products.push(product);
      });

      if (outOfStockAll) {
        dispatch(setProductOutOfStockError(true));
        return;
      }

      /*  let attributesObject = {};

      attributesObject = selectedProductCheckboxAttributesState[
        attributeid
      ].map(optionid => {
        return [attributeid, optionid];
      }); */

      dispatch(addToCartCheckboxProduct(itemCount, products, 0, false, vid));
    } else if (
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length > 0
    ) {
      let itemCount = 1 + selectedCheckBoxItemsState.length;
      let products = [];

      // add the actual product to the list of products
      let productItself = {
        distributorId: priceState.prices.find(
          inv => inv.distributorId == firstDistId
        ).distributorId,
        id: priceState.itemid,
        qty: 1
      };

      products.push(productItself);

      selectedCheckBoxItemsState.forEach(item => {
        let product = {};
        product.id = item.id;
        product.distributorId = item.distId;
        product.qty = item.qty;
        products.push(product);
      });

      dispatch(
        addToCartCheckboxProduct(
          itemCount,
          products,
          0,
          attributesObject,
          false,
          vid
        )
      );
    } else if (!itemDetailState.personalized) {
      console.info("firstDist", firstDist, vid);

      dispatch(
        getDeliveryOptions(
          distributorId,
          itemDetailState.code,
          packagePriceEnabledState ? selectedPackage.text : numberOfItems,
          itemDetailState.itemid,
          attributesObject,
          quoteMode
        )
      );
    }
  };
  console.info("distid", distributorId);

  const handleSetQuantityInput = e => {
    const value = Number(e.target.value);
    if (value > 0 && String(value).length <= 9)
      setNumberOfItems(Number(e.target.value));
  };

  const renderAddToCartBtnText = () => {
    if (personalizedQuestionsProgressState.displayQuestions) {
      if (
        personalizedQuestionsProgressState.progress === 3 &&
        bundleItemsState
      ) {
        console.info(
          "cheque initial4",
          bundleItemsState,
          bundleItemsProgressState
        );
        if (
          (currentItemIsBundleItemState === false &&
            bundleItemsState &&
            bundleItemsState.length > 0) ||
          bundleItemsProgressState <
            (bundleItemsState && bundleItemsState.length - 1)
        )
          return (
            <span>
              Étape suivante<i className="material-icons">arrow_forward</i>
            </span>
          );
      }

      return personalizedQuestionsProgressState.progress === 3 ? (
        <span>Ajouter au panier</span>
      ) : (
        <span>
          Étape suivante<i className="material-icons">arrow_forward</i>
        </span>
      );
    } else
      return personalizedItemState ? (
        <span>
          Étape suivante<i className="material-icons">arrow_forward</i>
        </span>
      ) : (
        <span>Ajouter au panier</span>
      );
  };
  const renderPerUnitText = () => {
    if (perUnitState) {
      return (
        <strong className="add-to-cart-box--per-unit-text">
          {perUnitState}
        </strong>
      );
    } else return null;
  };

  const renderGrandTotal = () => {
    return (
      !isProductDetailsLoading &&
      packagePrices &&
      packagePrices.length > 0 && (
        <div className="add-to-cart-grand-total-wrapper">
          <div className="quantity-wrapper">
            <h6> Quantité:</h6>&nbsp;<h6>{selectedPackage.text}</h6>
          </div>
          <div className="sub-total-wrapper">
            <h6>Sous-total:</h6>&nbsp;
            <FormattedNumber
              value={selectedPackage.value}
              style="currency"
              currency={currency}
              children={value => (
                <h6 className="add-to-cart-grand-total-price">
                  {" "}
                  {selectedPackage.value === 0 ? "Free" : value}
                </h6>
              )}
            />
          </div>{" "}
        </div>
      )
    );
  };

  const renderStartingFrom = () => {
    if (
      packagePrices &&
      packagePrices.length > 0 &&
      packagePrices[0] &&
      packagePrices[0].prices &&
      packagePrices[0].prices[0] &&
      packagePrices[0].prices[0].price
    ) {
      let firstPackageOption = packagePrices[0].prices;
      console.info("packagePrices", packagePrices, firstPackageOption);
      return (
        <div className="first-package-price-wrapper">
          <span className="first-package-price-starting-from">
            À Partir de:
          </span>
          &nbsp;{" "}
          <FormattedNumber
            value={firstPackageOption[0].price}
            style="currency"
            currency={currency}
            children={value => (
              <span className="first-package-price">{value}</span>
            )}
          />
          <span className="first-package-unit">{` / ${
            firstPackageOption[0].quantity
          } ${
            productQuantityType || firstPackageOption[0].quantity === 1
              ? "Produit"
              : "Produits"
          }`}</span>
        </div>
      );
    } else return null;
  };

  const renderAddToCartPreviousBtn = () => {
    return (
      personalizedQuestionsProgressState.progress > 0 && (
        <Button
          onClick={() => {
            dispatch(
              setPersonalizedQuestionsProgressAction({
                displayQuestions:
                  personalizedQuestionsProgressState.progress === 1
                    ? false
                    : true,
                progress: personalizedQuestionsProgressState.progress - 1
              })
            );
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <i className="material-icons">arrow_back</i>Étape précédente
          </span>
        </Button>
      )
    );
  };

  const renderPricingTable = () => {
    if (
      packagePrices &&
      packagePrices.length > 0 &&
      packagePrices[0] &&
      packagePrices[0].prices &&
      packagePrices[0].prices[0] &&
      packagePrices[0].prices[0].price
    ) {
      const indexOfTheLongestPackagePricesArray = packagePrices.reduce(
        (a, c, i) => {
          if (c.prices && c.prices.length > a.size) {
            return { index: i, size: c.prices.length };
          }
          return a;
        },
        { index: 0, size: 0 }
      );

      const bracketOfQuantity = packagePrices[
        indexOfTheLongestPackagePricesArray.index
      ].prices.reduce((a, c) => {
        a.push(c.quantity);
        return a;
      }, []);

      console.info(
        "indexOfTheLongestPackagePricesArray",
        indexOfTheLongestPackagePricesArray,
        packagePrices
      );
      return (
        <div className="pricing-table-wrapper">
          <div className="pricing-table-header">
            <h5>Prix</h5>
            <h5
              style={{ cursor: "pointer" }}
              onClick={() => setDisplayPricingTable(!displayPricingTable)}
            >
              {displayPricingTable ? `Casher` : `Montre`}
            </h5>
          </div>
          <div
            className="pricing-table scroll-bar-thin-style--horizontal"
            style={{ height: displayPricingTable ? "" : "0px" }}
          >
            <table>
              <thead>
                <tr>
                  <th>
                    {
                      /* fistPricePackageLabelIncludesCopyWord
                      ? "Copies"
                      : */ "Quantité"
                    }
                  </th>
                  {packagePrices[
                    indexOfTheLongestPackagePricesArray.index
                  ].prices.map(p => (
                    <th key={p.quantity}>{p.quantity}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {packagePrices.map((p, i) => (
                  <tr key={i}>
                    <td>{p.label ? p.label : "Prix"}</td>
                    {bracketOfQuantity.map(q => {
                      const foundPrice = p.prices.find(pr => pr.quantity === q);
                      if (foundPrice) {
                        return (
                          <td key={foundPrice.price}>${foundPrice.price}</td>
                        );
                      } else {
                        return <td key={i}></td>;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else return null;
  };

  console.info("selectedPackage", selectedPackage);

  const renderQuantitySelector = () => {
    if (packagePrices && packagePrices.length > 0) {
      const unitTextHandler = qty => {
        return qty > 1 ? "produits" : "produit";
      };

      let ddtext = null;
      let indexOfPackagePrice = 0;

      if (
        selectedProductAttributesState &&
        selectedProductAttributesState.hasOwnProperty(
          mainItemIdState || itemIdState
        )
      ) {
        console.info(
          "tempIndex1",
          selectedProductAttributesState[mainItemIdState || itemIdState]
        );
        let attrs =
          selectedProductAttributesState[mainItemIdState || itemIdState];

        let firstAttr = attrs && attrs[Object.keys(attrs)[0]];

        ddtext = firstAttr && firstAttr.ddtext;

        if (ddtext) {
          let tempIndex = packagePrices.findIndex(
            p => p.label.toLowerCase() === ddtext.toLowerCase()
          );
          console.info("tempIndex", tempIndex);

          if (tempIndex !== -1) {
            indexOfPackagePrice = tempIndex;
          }
        }
      }

      let options = packagePrices[indexOfPackagePrice].prices.reduce(
        (a, c, i) => {
          const unitText = unitTextHandler(c.quantity);
          a.push({
            value: c.price,
            text: c.quantity,
            label: i === 0 ? `${unitText} (recommandé)` : `${unitText}`
          });

          return a;
        },
        []
      );

      let defaultValue = null;

      if (
        biidState &&
        basketState &&
        basketState.products &&
        basketState.products.length > 0
      ) {
        const foundBiidProduct = basketState.products.find(
          p => p.id === biidState
        );
        if (foundBiidProduct) {
          defaultValue = foundBiidProduct.qty;
        }
      }
      return (
        <div
          className={`quantity-selector-wrapper${
            packagePrices.length < 1 ? " no-table" : ""
          }`}
        >
          <Select
            packagePrices={packagePrices}
            options={options}
            field={selectedPackage.key}
            state={selectedPackage}
            setState={setSelectedPackage}
            label="Sélectionnez la quantité:"
            defaultValue={defaultValue}
            lastInputRef={lastInputRef}
          />
        </div>
      );
    } else return null;
  };

  const handleValidation = progress => {
    let failedValidationFields = {};

    if (
      personalizedQuestionsState &&
      Object.keys(personalizedQuestionsState).length > 0 &&
      personalizedQuestionsValidationsState &&
      Object.keys(personalizedQuestionsValidationsState).length > 0
    ) {
      const handleValidationForTheSection = field => {
        let tabQuestionsIds = personalizedQuestionsState[field].reduce(
          (a, c) => {
            a.push(c.quesID);
            return a;
          },
          []
        );

        const validationsQuestionIds = Object.keys(
          personalizedQuestionsValidationsState
        );

        validationsQuestionIds.forEach(qID => {
          if (
            tabQuestionsIds.includes(qID) &&
            personalizedQuestionsValidationsState[qID]
          ) {
            if (personalizedQuestionsValidationsState[qID] === "required") {
              const input = personalizedQuestionInputsState[qID];
              console.info("input3", input);
              if (!input) {
                failedValidationFields[qID] = {
                  qID,
                  dID: null,
                  validationType: "required"
                };
              }
            }

            const detailIds =
              typeof personalizedQuestionsValidationsState[qID] === "object" &&
              Object.keys(personalizedQuestionsValidationsState[qID]);
            console.info("input33", detailIds);

            detailIds &&
              detailIds.forEach(dID => {
                const validationTypes = Object.keys(
                  personalizedQuestionsValidationsState[qID][dID]
                );
                const active =
                  personalizedQuestionsValidationsState[qID][dID].active ||
                  personalizedQuestionsValidationsState[qID][dID].active ===
                    undefined;

                validationTypes.forEach(validationType => {
                  if (validationType === "type" && active) {
                    const validation =
                      personalizedQuestionsValidationsState[qID][dID][
                        validationType
                      ];
                    console.info("validation22", validation);

                    if (
                      personalizedQuestionInputsState &&
                      personalizedQuestionInputsState[dID] !== undefined
                    ) {
                      const input = personalizedQuestionInputsState[dID];

                      const validateEmail = email => {
                        return email.match(
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
                        );
                      };
                      if (validation === "email") {
                        if (!validateEmail(input)) {
                          if (
                            failedValidationFields.hasOwnProperty(qID) === false
                          ) {
                            failedValidationFields[qID] = { [dID]: [] };
                          }
                          failedValidationFields[qID][dID].push({
                            qID,
                            dID,
                            validationType,
                            validation,
                            input
                          });
                        }
                      } else if (validation === "required") {
                        if (!input) {
                          if (
                            failedValidationFields.hasOwnProperty(qID) === false
                          ) {
                            failedValidationFields[qID] = { [dID]: [] };
                          }
                          failedValidationFields[qID][dID].push({
                            qID,
                            dID,
                            validationType,
                            validation,
                            input
                          });
                        }
                      }
                    }
                  }
                  if (validationType === "length" && active) {
                    const validation =
                      personalizedQuestionsValidationsState[qID][dID][
                        validationType
                      ];
                    const { allowBlank, min, max } = validation;

                    if (
                      personalizedQuestionInputsState &&
                      personalizedQuestionInputsState[dID] !== undefined
                    ) {
                      const input = personalizedQuestionInputsState[dID];

                      if (allowBlank && input === "") {
                      } else if (
                        input === "Not provided:  Leave blank" ||
                        input.length < min ||
                        input.length > max
                      ) {
                        if (
                          failedValidationFields.hasOwnProperty(qID) === false
                        ) {
                          failedValidationFields[qID] = { [dID]: [] };
                        }
                        failedValidationFields[qID][dID].push({
                          qID,
                          dID,
                          validationType,
                          validation,
                          input
                        });
                      }
                    }
                  }
                  if (validationType === "digitsOnly" && active) {
                    const validation =
                      personalizedQuestionsValidationsState[qID][dID][
                        validationType
                      ];

                    if (
                      validation &&
                      personalizedQuestionInputsState &&
                      personalizedQuestionInputsState[dID] !== undefined
                    ) {
                      const input = personalizedQuestionInputsState[dID];
                      if (isNaN(input)) {
                        if (
                          failedValidationFields.hasOwnProperty(qID) === false
                        ) {
                          failedValidationFields[qID] = { [dID]: [] };
                        }
                        failedValidationFields[qID][dID].push({
                          qID,
                          dID,
                          validationType,
                          validation,
                          input
                        });
                      }
                    }
                  }
                });
              });
          }
        });
      };
      if (progress === 1) {
        handleValidationForTheSection("textFields");
      } else if (progress === 2) {
        handleValidationForTheSection("restOfTheFields");
        handleValidationForTheSection("dependedFields");
      }
    }
    dispatch(setChequeValidationErrors(failedValidationFields));
    return failedValidationFields;
  };
  console.info("quantityType", productQuantityType);

  const lastInputRef = useRef(null);
  const firstInputRef = useRef(null);

  const [tempRef, setTempRef] = useState({ first: null, last: null });
  return (
    <React.Fragment>
      <div id="addToCardGrid">
        <div style={{ display: "none" }} id="pickupShipInfo"></div>

        {personalizedItemState &&
          !personalizedQuestionsProgressState.displayQuestions && (
            <SectionButtons
              {...{ activeSection, setActiveSection, quantityTabState: true }}
            />
          )}
        <div className="buyBox">
          <div className="buy-box-qty-price-container">
            <div style={{ display: "none" }} className="inStock-container">
              {inStock > 0 ? (
                <div className="inStock-wrapper">
                  <label>{translate("js.item.stock")}.</label>
                </div>
              ) : null}
            </div>
            <div
              className="buy-box-qty-wrapper"
              style={{
                display:
                  packagePriceEnabledState ||
                  (checkBoxItemsState && checkBoxItemsState.length > 0)
                    ? "none"
                    : ""
              }}
            >
              {!isProductDetailsLoading && (
                <div id="buyBoxQtyBox">
                  <div className="qtyControlsBox">
                    <div className="qtyControlsBtns">
                      <div
                        className="qtyControlsPlus no-select"
                        onClick={() => setNumberOfItems(numberOfItems + 1)}
                        style={{ cursor: "pointer" }}
                      >
                        <span>+</span>
                      </div>
                      <div
                        className="qtyControlsMinus no-select"
                        onClick={() => {
                          if (numberOfItems - 1 > 0) {
                            setNumberOfItems(numberOfItems - 1);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span>-</span>
                      </div>
                    </div>
                    <label className="sr-only" for="quantity_product">
                      Quantity
                    </label>
                    <input
                      id="quantity_product"
                      size={String(numberOfItems).length}
                      className="qtyControlsInput"
                      type="text"
                      value={numberOfItems}
                      onChange={e => {
                        handleSetQuantityInput(e);
                      }}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              )}
            </div>

            <div
              /*   style={{
                visibility: isProductDetailsLoading ? "hidden" : "visible"
              }} */
              className="buy-box-price-wrapper"
            >
              {renderStartingFrom()}
              <div id="buyBoxPrice">
                {!isProductDetailsLoading && !packagePriceEnabledState && (
                  <FormattedNumber
                    value={price / priceConvert}
                    style="currency"
                    currency={currency}
                  />
                )}

                {renderPricingTable()}
                {renderQuantitySelector()}
                <Attributes data={props.data} />
              </div>
              {renderPerUnitText()}
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div id="buyBoxItemInfo2">
                <GrandTotalCheckBoxItems price={price} />
              </div>
              <div id="buyBoxItemInfo3"></div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "auto" }}>
            {renderGrandTotal()}
            <div className="col-xs-12 stockPage">
              {!inStock && !isProductDetailsLoading && (
                <React.Fragment>
                  <div className="add-to-cart-stock-status">
                    Sorry this item is out of stock.
                  </div>
                </React.Fragment>
              )}
              <div
                id="buyBoxAddToCartBtn"
                className={inStock ? "" : "out-of-stock"}
              >
                <div className="add-to-cart-btn-container">
                  {renderAddToCartPreviousBtn()}
                  <Button
                    primary
                    tabIndex="0"
                    onClick={() => {
                      // ONCLICK ADDTOCART
                      const callBack = (statusCode, bundleItem = false) => {
                        console.info(
                          "post personalized questions",
                          statusCode,
                          bundleItem
                        );
                        if (statusCode === 200) {
                          // clear the questions
                          dispatch(setPersonalizedQuestionsAction({}));
                          // clear the progress state
                          dispatch(
                            setPersonalizedQuestionsProgressAction({
                              displayQuestions: false,
                              displayBundleItemsMessage: false,
                              progress: 1,
                              inputs: {},
                              font: {
                                selectedFont: "",
                                "Imprint Colour": "",
                                "2nd Ink Color": ""
                              },
                              logo: {
                                logoMode: "",
                                selectedClipart: null,
                                uploadedLogo: null
                              }
                            })
                          );

                          let quoteMode = false;
                          if (!bundleItem) {
                            dispatch(
                              showContinueModalAfterAddingToCartAction(
                                quoteMode
                              )
                            );

                            dispatch({
                              type: addToCartActions.SUCCESS_ADD_TO_CART,
                              payload: quoteMode ? "addQuote" : "addItem"
                            });
                            dispatch({
                              type: REQUEST_BASKET_AFTER_ADDING_TO_CART
                            });
                          } else {
                            console.info("bundle item modified");
                          }
                        }
                      };
                      if (personalizedQuestionsProgressState.displayQuestions) {
                        if (personalizedQuestionsProgressState.progress === 3) {
                          // main product is being displayed and will fetch the first bundle product available
                          if (
                            currentItemIsBundleItemState === false &&
                            bundleItemsState &&
                            bundleItemsState.length > 0 &&
                            bundleItemsProgressState === 0
                          ) {
                            let firstBundleItemId = bundleItemsState[0].id;
                            dispatch(
                              reFetchProductInitialState(
                                null,
                                firstBundleItemId
                              )
                            );
                            dispatch(
                              fetchingProductRequestSaga(firstBundleItemId)
                            );

                            return;
                          }
                          // there are more bundle items to customize
                          else if (
                            currentItemIsBundleItemState === true &&
                            bundleItemsState &&
                            bundleItemsState.length > 0 &&
                            bundleItemsProgressState <
                              bundleItemsState.length - 1
                          ) {
                            const nextProgressState =
                              bundleItemsProgressState + 1;
                            const currentBundleItemId =
                              bundleItemsState[bundleItemsProgressState].id;
                            const nextBundleItemId =
                              bundleItemsState[nextProgressState].id;

                            postPersonalizedQuestions(
                              currentBundleItemId,
                              personalizedQuestionInputsState,
                              statusCode => callBack(statusCode, true),
                              true
                            );
                            dispatch(
                              reFetchProductInitialState(null, nextBundleItemId)
                            );
                            dispatch(
                              fetchingProductRequestSaga(nextBundleItemId)
                            );

                            return;
                          }

                          return postPersonalizedQuestions(
                            itemDetailState.itemId,
                            personalizedQuestionInputsState,
                            callBack
                          );
                        }

                        let failedValidationFields = handleValidation(
                          personalizedQuestionsProgressState.progress
                        );
                        if (Object.keys(failedValidationFields).length > 0) {
                          return;
                        }
                        dispatch(
                          setPersonalizedQuestionsProgressAction({
                            progress:
                              personalizedQuestionsProgressState.progress + 1
                          })
                        );
                      } else {
                        console.info(
                          "add to cart 333",
                          personalizedItemState,
                          bundleItemsProgressState,
                          bundleItemsState.length - 1
                        );
                        // does not have personalized questions but it's the last bundle item
                        if (
                          currentItemIsBundleItemState === true &&
                          bundleItemsProgressState ===
                            bundleItemsState.length - 1 &&
                          personalizedItemState === false
                        ) {
                          return postPersonalizedQuestions(
                            itemDetailState.itemId,
                            personalizedQuestionInputsState,
                            callBack
                          );
                        } else if (
                          currentItemIsBundleItemState === true &&
                          personalizedItemState === false &&
                          bundleItemsProgressState < bundleItemsState.length - 1
                        ) {
                          // current item is a non-personalized bundle item and there are more bundle items to fetch
                          const nextProgressState =
                            bundleItemsProgressState + 1;
                          const currentBundleItemId =
                            bundleItemsState[bundleItemsProgressState].id;
                          const nextBundleItemId =
                            bundleItemsState[nextProgressState].id;

                          dispatch(
                            reFetchProductInitialState(null, nextBundleItemId)
                          );
                          dispatch(
                            fetchingProductRequestSaga(nextBundleItemId)
                          );

                          return;
                        }

                        handleAddToCart();
                      }
                    }}
                    onKeyDown={e => {
                      console.info(
                        "SHIFT KEY",
                        e.keyCode,
                        firstInputRef,
                        firstInputRef.current,
                        personalizedQuestionsProgressState.progress
                      );
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        // ONCLICK ADDTOCART
                        const callBack = (statusCode, bundleItem = false) => {
                          console.info(
                            "post personalized questions",
                            statusCode,
                            bundleItem
                          );
                          if (statusCode === 200) {
                            // clear the questions
                            dispatch(setPersonalizedQuestionsAction({}));
                            // clear the progress state
                            dispatch(
                              setPersonalizedQuestionsProgressAction({
                                displayQuestions: false,
                                displayBundleItemsMessage: false,
                                progress: 1,
                                inputs: {},
                                font: {
                                  selectedFont: "",
                                  "Imprint Colour": "",
                                  "2nd Ink Color": ""
                                },
                                logo: {
                                  logoMode: "",
                                  selectedClipart: null,
                                  uploadedLogo: null
                                }
                              })
                            );

                            let quoteMode = false;
                            if (!bundleItem) {
                              dispatch(
                                showContinueModalAfterAddingToCartAction(
                                  quoteMode
                                )
                              );

                              dispatch({
                                type: addToCartActions.SUCCESS_ADD_TO_CART,
                                payload: quoteMode ? "addQuote" : "addItem"
                              });
                              dispatch({
                                type: REQUEST_BASKET_AFTER_ADDING_TO_CART
                              });
                            } else {
                              console.info("bundle item modified");
                            }
                          }
                        };
                        if (
                          personalizedQuestionsProgressState.displayQuestions
                        ) {
                          if (
                            personalizedQuestionsProgressState.progress === 3
                          ) {
                            // main product is being displayed and will fetch the first bundle product available
                            if (
                              currentItemIsBundleItemState === false &&
                              bundleItemsState &&
                              bundleItemsState.length > 0 &&
                              bundleItemsProgressState === 0
                            ) {
                              let firstBundleItemId = bundleItemsState[0].id;
                              dispatch(
                                reFetchProductInitialState(
                                  null,
                                  firstBundleItemId
                                )
                              );
                              dispatch(
                                fetchingProductRequestSaga(firstBundleItemId)
                              );

                              return;
                            }
                            // there are more bundle items to customize
                            else if (
                              currentItemIsBundleItemState === true &&
                              bundleItemsState &&
                              bundleItemsState.length > 0 &&
                              bundleItemsProgressState <
                                bundleItemsState.length - 1
                            ) {
                              const nextProgressState =
                                bundleItemsProgressState + 1;
                              const currentBundleItemId =
                                bundleItemsState[bundleItemsProgressState].id;
                              const nextBundleItemId =
                                bundleItemsState[nextProgressState].id;

                              postPersonalizedQuestions(
                                currentBundleItemId,
                                personalizedQuestionInputsState,
                                statusCode => callBack(statusCode, true),
                                true
                              );
                              dispatch(
                                reFetchProductInitialState(
                                  null,
                                  nextBundleItemId
                                )
                              );
                              dispatch(
                                fetchingProductRequestSaga(nextBundleItemId)
                              );

                              return;
                            }

                            return postPersonalizedQuestions(
                              itemDetailState.itemId,
                              personalizedQuestionInputsState,
                              callBack
                            );
                          }

                          let failedValidationFields = handleValidation(
                            personalizedQuestionsProgressState.progress
                          );
                          if (Object.keys(failedValidationFields).length > 0) {
                            return;
                          }
                          dispatch(
                            setPersonalizedQuestionsProgressAction({
                              progress:
                                personalizedQuestionsProgressState.progress + 1
                            })
                          );
                        } else {
                          console.info(
                            "add to cart 333",
                            personalizedItemState,
                            bundleItemsProgressState,
                            bundleItemsState.length - 1
                          );
                          // does not have personalized questions but it's the last bundle item
                          if (
                            currentItemIsBundleItemState === true &&
                            bundleItemsProgressState ===
                              bundleItemsState.length - 1 &&
                            personalizedItemState === false
                          ) {
                            return postPersonalizedQuestions(
                              itemDetailState.itemId,
                              personalizedQuestionInputsState,
                              callBack
                            );
                          } else if (
                            currentItemIsBundleItemState === true &&
                            personalizedItemState === false &&
                            bundleItemsProgressState <
                              bundleItemsState.length - 1
                          ) {
                            // current item is a non-personalized bundle item and there are more bundle items to fetch
                            const nextProgressState =
                              bundleItemsProgressState + 1;
                            const currentBundleItemId =
                              bundleItemsState[bundleItemsProgressState].id;
                            const nextBundleItemId =
                              bundleItemsState[nextProgressState].id;

                            dispatch(
                              reFetchProductInitialState(null, nextBundleItemId)
                            );
                            dispatch(
                              fetchingProductRequestSaga(nextBundleItemId)
                            );

                            return;
                          }

                          handleAddToCart();
                        }
                        if (props.sectionRef && props.sectionRef.current) {
                          setTimeout(() => {
                            props.sectionRef.current.focus();
                          }, 250);
                        }
                      } else if (
                        e.shiftKey &&
                        e.keyCode === 9 &&
                        lastInputRef &&
                        lastInputRef.current
                      ) {
                        e.preventDefault();
                        lastInputRef.current.focus();
                      } else if (
                        e.shiftKey &&
                        e.keyCode === 9 &&
                        tempRef.last &&
                        tempRef.last.current
                      ) {
                        e.preventDefault();
                        tempRef.last.current.focus();
                      } else if (
                        !e.shiftKey &&
                        e.keyCode === 9 &&
                        props.socialRef &&
                        props.socialRef.current
                      ) {
                        e.preventDefault();
                        props.socialRef.current.focus();
                      }
                      /* if (firstInputRef && firstInputRef.current) {
                      console.log("SHIFT HEYHEYHEYHEYHEY");
                      firstInputRef.current.focus();
                    } */
                    }}
                    ref={props.buttonRef}
                  >
                    <div className="add-to-cart-btn-text">
                      {renderAddToCartBtnText()}
                    </div>
                  </Button>
                </div>
                {requestingAddToCartState ? (
                  <Loading className="addToCartLoading" />
                ) : null}
                {!displayQuestionsState && (
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Nous apprécions votre confidentialité et votre sécurité. Notre système de sécurité crypte vos informations lors de la transmission. Les détails de votre carte de crédit sont envoyés à un processeur de paiement et ne sont pas partagés avec ce site ou des vendeurs tiers."
                    >
                      <div
                        className="Securetransaction"
                        onClick={handleTooltipOpen}
                      >
                        <i className="material-icons">lock_outline</i>
                        Transaction sécurisée
                      </div>
                    </Tooltip>
                  </ClickAwayListener>
                )}

                <div></div>
              </div>

              {/*         <div
                id="buyBoxQuoteBtn"
                className={`add-to-order${inStock ? "" : " active"}`}
              >
                <div
                  className="addToCartBtn"
                  title="Add to Order"
                  onClick={() => handleAddToCart(true)}
                >
                  <div>
                    <span>Add to Order</span>
                  </div>
                  <div>
                    <i className="material-icons add-icon">description</i>
                  </div>
                </div>
              </div> */}
              {/* <div id="buyBoxEnquiryBtn">
                <div
                  id="enquiry-204"
                  className="addToCartBtn sendEnquiryBtn"
                  title={translate("js.item.enquiry")}
                  onClick={handleEnquiryModalOpenClicked}
                >
                  <div>{translate("js.item.enquiry")}</div>
                  <div>
                    <span>
                      <i className="material-icons add-icon">mail_outline</i>
                    </span>
                  </div>
                </div>
              </div> */}
              {/*     {isMobileState && (
                <WishListBarMobile
                  data={props.data}
                  productUnavailable={props.productUnavailable}
                  price={price}
                />
              )} */}
              {!inStock && (
                <React.Fragment>
                  <div className="add-to-cart-stock-status">
                    The supplier may still be in progress updating the inventory
                    for this product. Click the Chat button below to chat with
                    the supplier to confirm availability.
                  </div>
                  {/* <NotifyOnReStock
                      supplier={{
                        stock: priceState.invs[0].instock,
                        code: priceState.code,
                        itemid: priceState.itemid
                      }}
                      renderedInsideAddToCartBox={true}
                    /> */}
                </React.Fragment>
              )}
            </div>
          </div>
          <ChequeCustomization
            buttonRef={props.buttonRef}
            lastInputRef={lastInputRef}
            firstInputRef={firstInputRef}
            imageCaroRef={props.imageCaroRef}
            setTempRef={setTempRef}
            tempRef={tempRef}
            sectionRef={props.sectionRef}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </div>
        {/*  <ChatTheSeller /> */}
      </div>
    </React.Fragment>
  );
};

export default AddToCartBox;
